/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

 require("./bootstrap");

 // window.Vue = require('vue');
 import Vue from "vue";
 import ElementUI from "element-ui";
 import 'element-ui/lib/theme-chalk/index.css'
 //import * as qz from 'qz-tray';
 import Axios from "axios";
 import lang from "element-ui/lib/locale/lang/es";
 import locale from "element-ui/lib/locale";
 import VueApexCharts from 'vue-apexcharts'
 import Select2 from 'v-select2-component';
 Vue.component('Select2', Select2);
 Vue.use(VueApexCharts)
 Vue.component('apexchart', VueApexCharts)
 locale.use(lang);

 //Vue.use(ElementUI)
 Vue.use(ElementUI, { size: "small" });
 Vue.prototype.$eventHub = new Vue();
 Vue.prototype.$http = Axios;
 //Vue.prototype.$http.defaults.withCredentials = false;

 // import VueCharts from 'vue-charts'
 // Vue.use(VueCharts);
 // import { TableComponent, TableColumn } from 'vue-table-component';
 //
 // Vue.component('table-component', TableComponent);
 // Vue.component('table-column', TableColumn);
 //RESTAURANT
 Vue.component(
     "inventory-index",
     require("../../modules/inventory/Resources/assets/js/inventory/inventory.vue").default
 );
 Vue.component(
     "restaurant-food-list",
     require("../../modules/Restaurant/Resources/assets/js/views/food/index.vue").default
 );
 Vue.component(
     "tenant-restaurant-index",
     require("../../modules/Restaurant/Resources/assets/js/views/restaurant.vue").default
 );
 Vue.component(
     "tenant-restaurant-kitchen",
     require("../../modules/Restaurant/Resources/assets/js/views/kitchen.vue").default
 );
 Vue.component(
     "tenant-restaurant-items",
     require("../../modules/Restaurant/Resources/assets/js/views/configuration/items.vue").default
 );
 //restaurant-ordens-index
 Vue.component(
     "restaurant-ordens-index",
     require("../../modules/Restaurant/Resources/assets/js/views/ordens/index.vue").default
 );
 Vue.component(
     "restaurant-worker-login",
     require("../../modules/Restaurant/Resources/assets/js/views/login/login.vue").default
 );
 Vue.component(
     "tenant-item-sets-index",
     require("./views/item_sets/index.vue").default

 );
 Vue.component(
     "restaurant-worker-dashboard",
     require("../../modules/Restaurant/Resources/assets/js/views/worker/dashboard.vue").default
 );
 Vue.component(
     "restaurant-kitchen-dashboard",
     require("../../modules/Restaurant/Resources/assets/js/views/kitchen/dashboard.vue").default
 );
 Vue.component(
     "tenant-restaurant-workers",
     require("../../modules/Restaurant/Resources/assets/js/views/workers/workers.vue").default
 );
 Vue.component(
     "tenant-restaurant-pos",
     require("../../modules/Restaurant/Resources/assets/js/views/pos/index.vue").default
 );
 Vue.component(
     "tenant-dashboard-index",
     require("../../modules/Dashboard/Resources/assets/js/views/index.vue").default
 );
 Vue.component("x-graph", require("./components/graph/src/Graph.vue").default);
 Vue.component("x-graph-line", require("./components/graph/src/GraphLine.vue").default);
 Vue.component( "tenant-companies-form", require("./views/companies/form.vue").default);
 Vue.component(
     "tenant-account-export",require("../../modules/Format/Resources/assets/js/views/account/format.vue").default
 );
 Vue.component(
     "tenant-companies-logo",
     require("./views/companies/logo.vue").default
 );
 Vue.component(
     "tenant-certificates-index",
     require("./views/certificates/index.vue").default
 );
 Vue.component(
     "tenant-certificates-form",
     require("./views/certificates/form.vue").default
 );
 Vue.component(
     "tenant-configurations-form",
     require("./views/configurations/form.vue").default
 );
 Vue.component(
     "tenant-configurations-visual",
     require("./views/configurations/visual.vue").default
 );
 Vue.component(
     "tenant-configurations-pdf",
     require("./views/configurations/pdf_templates.vue").default
 );
 // Vue.component('tenant-driver-index', require('./views/driver/index.vue'));
 // Vue.component('tenant-series-form', require('./views/series/form.vue'));
 // Vue.component(
 //     "tenant-bank_accounts-index",
 //     require("./views/bank_accounts/index.vue").default
 // );
 Vue.component("tenant-items-index", require("./views/items/index.vue").default);
 Vue.component(
     "tenant-persons-index",
     require("./views/persons/index.vue").default
 );

 // Vue.component('tenant-customers-index', require('./views/customers/index.vue'));
 // Vue.component('tenant-suppliers-index', require('./views/suppliers/index.vue'));
 Vue.component("tenant-users-form", require("./views/users/form.vue").default);


 Vue.component(
     "tenant-documents-index",
     require("./views/documents/index.vue").default
 );
 Vue.component(
     "restaurant-documents-index",
     require("../../modules/Restaurant/Resources/assets/js/views/documents/index.vue").default
 );
 Vue.component(
     "tenant-documents-invoice",
     require("./views/documents/invoice.vue").default
 );
 // Vue.component(
 //     "tenant-documents-invoice-pos",
 //     require("../../modules/Restaurant/Resources/assets/js/views/documents/invoice.vue").default
 // );
 // Vue.component(
 //     "tenant-documents-invoicetensu",
 //     require("./views/documents/invoicetensu.vue").default
 // );
 Vue.component(
     "tenant-documents-note",
     require("./views/documents/note.vue").default
 );
 Vue.component(
     "tenant-summaries-index",
     require("./views/summaries/index.vue").default
 );
 Vue.component(
     "tenant-voided-index",
     require("./views/voided/index.vue").default
 );
 // Vue.component(
 //     "tenant-search-index",
 //     require("./views/search/index.vue").default
 // );
 Vue.component(
     "tenant-options-form",
     require("./views/options/form.vue").default
 );
 Vue.component(
     "tenant-unit_types-index",
     require("./views/unit_types/index.vue").default
 );

 Vue.component("tenant-users-index", require("./views/users/index.vue").default);
 Vue.component(
     "tenant-establishments-index",
     require("./views/establishments/index.vue").default
 );
 // Vue.component(
 //     "tenant-charge_discounts-index",
 //     require("./views/charge_discounts/index.vue").default
 // );
 Vue.component("tenant-banks-index", require("./views/banks/index.vue").default);

 Vue.component(
     "tenant-currency-types-index",
     require("./views/currency_types/index.vue").default
 );

 Vue.component(
     "tenant-purchases-index",
     require("./views/purchases/index.vue").default
 );
 Vue.component(
     "tenant-purchases-form",
     require("./views/purchases/form.vue").default
 );
 Vue.component(
     "tenant-purchases-edit",
     require("./views/purchases/form_edit.vue").default
 );

 Vue.component(
     "tenant-attribute_types-index",
     require("./views/attribute_types/index.vue").default
 );

 Vue.component(
     "tenant-warehouses",
     require("./views/components/warehouses.vue").default
 );
 //Vue.component('tenant-calendar', require('./views/components/calendar.vue'));
 // Vue.component(
 //     "tenant-product",
 //     require("./views/components/products.vue").default
 // );
 Vue.component(
     "tenant-arqueos-form",
     require("./views/arqueos/index.vue").default
 );

 Vue.component("tenant-tasks-lists", require("./views/tasks/lists.vue").default);
 Vue.component("tenant-tasks-form", require("./views/tasks/form.vue").default);



 Vue.component(
     "tenant-sale-notes-index",
     require("./views/sale_notes/index.vue").default
 );
 Vue.component(
     "tenant-sale-notes-form",
     require("./views/sale_notes/form.vue").default
 );
 // Vue.component("punto-pos", require("./views/pos/index.vue").default);
 Vue.component("tenant-cash-index", require("../../modules/Restaurant/Resources/assets/js/views/cash/index.vue").default);
 Vue.component(
     "tenant-card-brands-index",
     require("./views/card_brands/index.vue").default
 );

 // Vue.component(
 //     "tenant-payment-method-index",
 //     require("./views/payment_method/index.vue").default
 // );
 Vue.component(
     "tenant-payment-method-index",
     require("./views/payment_method/index.vue").default
 );
 Vue.component(
     "tenant-category-index",
     require("./views/category/index.vue").default
 );

 // Modules

 Vue.component(
     "inventory-transfers-index",
     require("../../modules/Inventory/Resources/assets/js/transfers/index.vue").default
 );
 Vue.component(
     "tenant-account-format",
     require("../../modules/Format/Resources/assets/js/views/account/format.vue").default
 );

 // Vue.component(
 //     "tenant-expense-types-index",
 //     require("@viewsModuleExpense/expense_types/index.vue").default
 // );
 // Vue.component(
 //     "tenant-expense-reasons-index",
 //     require("@viewsModuleExpense/expense_reasons/index.vue").default
 // );
 // Vue.component(
 //     "tenant-expense-method-types-index",
 //     require("@viewsModuleExpense/expense_method_types/index.vue").default
 // );
 // Vue.component(
 //     "tenant-payment-method-types-index",
 //     require("@viewsModuleSale/payment_method_types/index.vue").default
 // );

 Vue.component(
     "warehouses-index",
     require("../../modules/Inventory/Resources/assets/js/warehouses/index.vue").default
 );

 Vue.component(
     "tenant-inventories-form",
     require("../../modules/Inventory/Resources/assets/js/config/form.vue").default
 );
 // Vue.component(
 //     "tenant-expenses-index",
 //     require("../../modules/Expense/Resources/assets/js/views/expenses/index.vue").default
 // );
 // Vue.component(
 //     "tenant-expenses-form",
 //     require("../../modules/Expense/Resources/assets/js/views/expenses/form.vue").default
 // );

 Vue.component(
     "tenant-documents-not-sent",
     require("../../modules/Document/Resources/assets/js/views/documents/not_sent.vue").default
 );
 Vue.component(
     "tenant-report-purchases-index",
     require("../../modules/Report/Resources/assets/js/views/purchases/index.vue").default
 );
 Vue.component(
     "tenant-report-documents-index",
     require("../../modules/Report/Resources/assets/js/views/documents/index.vue").default
 );
 Vue.component(
     "tenant-report-customers-index",
     require("../../modules/Report/Resources/assets/js/views/customers/index.vue").default
 );
 Vue.component(
     "tenant-report-items-index",
     require("../../modules/Report/Resources/assets/js/views/items/index.vue").default
 );
 Vue.component(
     "tenant-report-items-defeated",
     require("../../modules/Report/Resources/assets/js/views/items/defeated.vue").default
 );

 Vue.component(
     "tenant-report-sale_notes-index",
     require("../../modules/Report/Resources/assets/js/views/sale_notes/index.vue").default
 );

 Vue.component(
     "tenant-report-cash-index",
     require("../../modules/Report/Resources/assets/js/views/cash/index.vue").default
 );
 Vue.component(
    "report-sales-index",
    require("../../modules/Report/Resources/assets/js/views/sales/index.vue").default
);
 // Vue.component(
 //     "tenant-index-configuration",
 //     require("../../modules/BusinessTurn/Resources/assets/js/views/configurations/index.vue").default
 // );


 Vue.component(
     "tenant-series-configurations-index",
     require("../../modules/Document/Resources/assets/js/views/series_configurations/index.vue").default
 );



 Vue.component(
     "tenant-report-order-notes-consolidated-index",
     require("../../modules/Report/Resources/assets/js/views/order_notes_consolidated/index.vue").default
 );
 Vue.component(
     "tenant-report-general-items-index",
     require("../../modules/Report/Resources/assets/js/views/general_items/index.vue").default
 );
 Vue.component(
     "tenant-report-order-notes-general-index",
     require("../../modules/Report/Resources/assets/js/views/order_notes_general/index.vue").default
 );
 Vue.component(
     "tenant-report-sales-consolidated-index",
     require("../../modules/Report/Resources/assets/js/views/sales_consolidated/index.vue").default
 );

 Vue.component(
     "tenant-categories-index",
     require("../../modules/Item/Resources/assets/js/views/categories/index.vue").default
 );
 Vue.component(
     "tenant-brands-index",
     require("../../modules/Item/Resources/assets/js/views/brands/index.vue").default
 );

 Vue.component(
     "tenant-report-valued-kardex",
     require("../../modules/Inventory/Resources/assets/js/valued_kardex/index.vue").default
 );

 //Finance
 Vue.component(
     "tenant-finance-global-payments-index",
     require("../../modules/Finance/Resources/assets/js/views/global_payments/index.vue").default
 );
 Vue.component(
     "tenant-finance-balance-index",
     require("../../modules/Finance/Resources/assets/js/views/balance/index.vue").default
 );
 Vue.component(
     "tenant-finance-payment-method-types-index",
     require("../../modules/Finance/Resources/assets/js/views/payment_method_types/index.vue").default
 );
 Vue.component(
     "tenant-finance-unpaid-index",
     require("@viewsModuleFinance/unpaid/index.vue").default
 );
 // Vue.component(
 //     "tenant-finance-to-pay-index",
 //     require("@viewsModuleFinance/to_pay/index.vue").default
 // );
 Vue.component(
     "tenant-finance-income-index",
     require("@viewsModuleFinance/income/index.vue").default
 );
 Vue.component(
     "tenant-finance-income-form",
     require("@viewsModuleFinance/income/form.vue").default
 );
 Vue.component(
     "tenant-income-types-index",
     require("@viewsModuleFinance/income_types/index.vue").default
 );
 Vue.component(
     "tenant-income-reasons-index",
     require("@viewsModuleFinance/income_reasons/index.vue").default
 );




 Vue.component("x-input-service", require("./components/InputService.vue").default);

 // Vue.component(
 //     "tenant-items-ecommerce-index",
 //     require("./views/items_ecommerce/index.vue").default
 // );
 // Vue.component(
 //     "tenant-ecommerce-cart",
 //     require("./views/ecommerce/cart_dropdown.vue").default
 // );
 // Vue.component("tenant-tags-index", require("./views/tags/index.vue").default);
 // Vue.component(
 //     "tenant-promotions-index",
 //     require("./views/promotions/index.vue").default
 // );

 Vue.component(
     "tenant-person-types-index",
     require("./views/person_types/index.vue").default
 );

 Vue.component("x-form-group", require("./components/FormGroup.vue").default);

 //Cuenta
 Vue.component(
     "tenant-account-payment-index",
     require("./views/account/payment_index.vue").default
 );
 Vue.component(
     "tenant-account-configuration-index",
     require("./views/account/configuration.vue").default
 );

 //auto update

 //////////////////////////////////////////////////////////dubai//////////////////////////////////////////////////////////
 Vue.component(
     "tenant-groups-index",
     require("./views/groups/index.vue").default
 );
 //Vue.component('tenant-categories-index', require('./views/category/index.vue'));
 Vue.component(
     "tenant-subcategories-index",
     require("./views/subcategory/index.vue").default
 );
 Vue.component("tenant-boxes-index", require("./views/boxes/index.vue").default);
 //"./views/boxes/index.vue"
 Vue.component("tenant-boxes-expenses-index", require("../../modules/Restaurant/Resources/assets/js/views/expenses/index.vue").default);

 Vue.component("tenant-boxes-incomes-index", require("../../modules/Restaurant/Resources/assets/js/views/incomes/index.vue").default);
 Vue.component(
     "tenant-incomes-index",
     require("./views/incomebox/index.vue").default
 );
 Vue.component("tenant-boxes-reports",require("./views/boxes/report.vue").default);

 Vue.component("tenant-boxes-reports-pos",require("../../modules/Restaurant/Resources/assets/js/views/boxes/report.vue").default);

 Vue.component("tenant-login-default",require("./views/login/index.vue").default);

 const app = new Vue({
     el: "#main",
     // mounted: function () {
     //     //   postscribe('#charts', `<script src="http://127.0.0.1:8000/acorn/js/cs/charts.extend.js"><\/script>`)
     //    ///    postscribe('#dashboard', `<script src="http://127.0.0.1:8000/acorn/js/pages/dashboard.analytic.js"><\/script>`)
     //    let Script = document.createElement("script");
     //    Script.setAttribute("src", "http://127.0.0.1:8000/acorn/js/pages/dashboard.analytic.js");
     //    document.head.appendChild(Script);
     //      },
 });
