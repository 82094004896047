<template>
    <div class="card mb-0 pt-2 pt-md-0 rounded-top" v-loading="loader"  :element-loading-text="text_loader">
        <div class="card-header bg-primary rounded-top">
            <h6 class="my-0  text-white">Comprobante de Pago</h6>
        </div>
        <div class="card-body">
            <div class="invoice" v-if="company!=null">
                <header class="clearfix">
                    <div class="row">
                        <div class="col-sm-2 text-center mt-3 mb-0">
                            <logo url="/"
                                  :path_logo="(company.logo != null) ? `/storage/uploads/logos/${company.logo}` : ''"></logo>
                        </div>
                        <div class="col-sm-6 text-left mt-3 mb-0">
                            <address class="ib mr-2">
                                <span class="font-weight-bold">{{ company.name }}</span>
                                <br>
                                <div v-if="establishment.address != '-'">{{ establishment.address }},</div>
                                {{ establishment.district.description }}, {{ establishment.province.description }},
                                {{ establishment.department.description }} - {{ establishment.country.description }}
                                <br>
                                {{ establishment.email }} - <span
                                v-if="establishment.telephone != '-'">Telefonos: {{ establishment.telephone }}</span>
                            </address>
                        </div>
                        <div class="col-sm-4 pt-3">
                            <el-checkbox v-model="is_contingency" @change="changeEstablishment">¿Es comprobante de
                                contigencia?
                            </el-checkbox>
                            <template v-if="!is_client">

                                <!-- <el-checkbox v-model="form.has_prepayment" :disabled="prepayment_deduction">¿Es un pago anticipado?</el-checkbox>
                                <el-checkbox v-model="prepayment_deduction" @change="changePrepaymentDeduction" :disabled="form.has_prepayment">Deducción de los pagos anticipados</el-checkbox> -->

                                <el-checkbox v-model="form.has_prepayment" v-if="!prepayment_deduction"
                                             @change="changeHasPrepayment">¿Es un pago anticipado?
                                </el-checkbox>
                                <el-checkbox v-model="prepayment_deduction" @change="changePrepaymentDeduction"
                                             v-if="!form.has_prepayment">Deducción de los pagos anticipados
                                </el-checkbox>

                                <el-switch v-if="form.has_prepayment || prepayment_deduction"
                                           v-model="form.affectation_type_prepayment"
                                           @change="changeAffectationTypePrepayment"
                                           active-color="#409EFF"
                                           inactive-color="#409EFF"
                                           active-text="Exonerado"
                                           inactive-text="Gravado"
                                           :active-value="20"
                                           :inactive-value="10">
                                </el-switch>

                            </template>
                        </div>
                    </div>
                </header>
                <form autocomplete="off" @submit.prevent="submit">
                    <div class="form-body">
                        <div class="row">
                            <div class="col-lg-4 pb-2">
                                <div class="form-group" :class="{'has-danger': errors.document_type_id}">
                                    <!--<label class="control-label font-weight-bold text-info full-text">Tipo de comprobante</label>-->
                                    <!--<label class="control-label font-weight-bold text-info short-text">Tipo comprobante</label>-->
                                    <label class="control-label font-weight-bold text-primary">Tipo comprobante</label>
                                    <el-select v-model="form.document_type_id" @change="changeDocumentType"
                                               popper-class="el-select-document_type" dusk="document_type_id"
                                               >
                                        <el-option v-for="option in document_types" :key="option.id" :value="option.id"
                                                   :label="option.description"></el-option>
                                    </el-select>
                                    <small class="form-control-feedback" v-if="errors.document_type_id"
                                           v-text="errors.document_type_id[0]"></small>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group" :class="{'has-danger': errors.establishment_id}">
                                    <label class="control-label">Establecimiento</label>
                                    <el-select v-model="form.establishment_id" @change="changeEstablishment">
                                        <el-option v-for="option in establishments" :key="option.id" :value="option.id"
                                                   :label="option.description"></el-option>
                                    </el-select>
                                    <small class="form-control-feedback" v-if="errors.establishment_id"
                                           v-text="errors.establishment_id[0]"></small>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group" :class="{'has-danger': errors.operation_type_id}">
                                    <label class="control-label">Tipo Operación
                                        <template v-if="form.operation_type_id == '1001' && has_data_detraction">
                                            <a href="#" @click.prevent="showDialogDocumentDetraction = true"
                                               class="text-center font-weight-bold text-info"> [+ Ver datos]</a>
                                        </template>

                                    </label>
                                    <el-select v-model="form.operation_type_id" @change="changeOperationType">
                                        <el-option v-for="option in operation_types" :key="option.id" :value="option.id"
                                                   :label="option.description"></el-option>
                                    </el-select>
                                    <small class="form-control-feedback" v-if="errors.operation_type_id"
                                           v-text="errors.operation_type_id[0]"></small>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group" :class="{'has-danger': errors.series_id}">
                                    <label class="control-label">Serie</label>
                                    <el-select v-model="form.series_id">
                                        <el-option v-for="option in series" :key="option.id" :value="option.id"
                                                   :label="option.number"></el-option>
                                    </el-select>
                                    <small class="form-control-feedback" v-if="errors.series_id"
                                           v-text="errors.series_id[0]"></small>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group" :class="{'has-danger': errors.currency_type_id}">
                                    <label class="control-label">Moneda</label>
                                    <el-select v-model="form.currency_type_id" @change="changeCurrencyType">
                                        <el-option v-for="option in currency_types" :key="option.id" :value="option.id"
                                                   :label="option.description"></el-option>
                                    </el-select>
                                    <small class="form-control-feedback" v-if="errors.currency_type_id"
                                           v-text="errors.currency_type_id[0]"></small>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-4 pb-2">
                                <div class="form-group">
                                    <label class="control-label">Vendedores</label>

                                    <el-select v-model="form.user_id" filterable
                                                @change="filterCustomers()">
                                        <el-option v-for="option in array_users" :key="option.id" :value="option.id"
                                                   :label="option.name"></el-option>
                                    </el-select>
                                </div>
                            </div>

                            <div class="col-lg-4 pb-2">
                                <div class="form-group" :class="{'has-danger': errors.customer_id}">
                                    <label class="control-label font-weight-bold text-primary">
                                        Cliente
                                        <a href="#" @click.prevent="showDialogNewPerson = true">[+ Nuevo]</a>
                                    </label>
                                    <el-select ref="cliente" v-model="form.customer_id" filterable remote

                                               popper-class="el-select-customers"
                                               dusk="customer_id"
                                               placeholder="Escriba el nombre o número de documento del cliente"
                                               :remote-method="searchRemoteCustomers"
                                               @keyup.enter.native="keyupCustomer"
                                               :loading="loading_search"
                                               @change="changeCustomer">

                                        <el-option v-for="option in customers" :key="option.id" :value="option.id"
                                                   :label="option.description"></el-option>

                                    </el-select>
                                    <small class="form-control-feedback" v-if="errors.customer_id"
                                           v-text="errors.customer_id[0]"></small>
                                </div>

                            </div>
                            <div class="col-lg-4 pb-2">
                                <div class="form-group">
                                    <label class="control-label font-weight-bold text-primary">Dirección</label>
                                    <el-select v-model="form.customer_address_id">
                                        <el-option v-for="option in customer_addresses" :key="option.id"
                                                   :value="option.id" :label="option.address"></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <template v-if="!is_client">
                                <div class="col-lg-2 mt-2 mb-2" v-if="form.document_type_id=='03'">
                                    <div class="form-group">
                                        <el-checkbox v-model="is_receivable" class=" font-weight-bold"
                                                     @change="changeIsReceivable">¿Es venta por cobrar?
                                        </el-checkbox>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-lg-2">
                                <div class="form-group" :class="{'has-danger': errors.date_of_issue}">
                                    <!--<label class="control-label">Fecha de emisión</label>-->
                                    <label class="control-label">Fec. Emisión</label>
                                    <el-date-picker v-model="form.date_of_issue" type="date"
                                                    value-format="yyyy-MM-dd" :clearable="false"
                                                    @change="changeDateOfIssue"
                                                    :picker-options="datEmision"></el-date-picker>
                                    <small class="form-control-feedback" v-if="errors.date_of_issue"
                                           v-text="errors.date_of_issue[0]"></small>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group" :class="{'has-danger': errors.date_of_due}">
                                    <!--<label class="control-label full-text">Fecha de vencimiento</label>-->
                                    <!--<label class="control-label short-text">F. vencimiento</label>-->
                                    <label class="control-label">Fec. Vencimiento</label>
                                    <el-date-picker v-model="form.date_of_due" type="date" value-format="yyyy-MM-dd"
                                                    :clearable="false"></el-date-picker>
                                    <small class="form-control-feedback" v-if="errors.date_of_due"
                                           v-text="errors.date_of_due[0]"></small>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group" :class="{'has-danger': errors.exchange_rate_sale}">
                                    <label class="control-label">Tipo de cambio
                                        <el-tooltip class="item" effect="dark"
                                                    content="Tipo de cambio del día, extraído de SUNAT"
                                                    placement="top-end">
                                            <i class="fa fa-info-circle"></i>
                                        </el-tooltip>
                                    </label>
                                    <el-input v-model="form.exchange_rate_sale"> <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                    <small class="form-control-feedback" v-if="errors.exchange_rate_sale"
                                           v-text="errors.exchange_rate_sale[0]"></small>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <div class="form-group" :class="{'has-danger': errors.payment_condition_id}">
                                    <label class="control-label">Condición de pago</label>
                                    <el-select v-model="form.payment_condition_id" @change="changePaymentCondition">
                                        <el-option v-for="option in payment_conditions" :key="option.id"
                                                   :value="option.id" :label="option.name"></el-option>
                                    </el-select>
                                    <small class="form-control-feedback" v-if="errors.payment_condition_id"
                                           v-text="errors.payment_condition_id[0]"></small>
                                </div>
                            </div>
                            <div class="col-lg-2" v-if="show_restriction==1">
                                <div class="form-group" :class="{'has-danger': errors.payment_condition_id}">
                                    <label class="control-label">Restringir de Stock</label>
                                    <div class="form-group" :class="{'has-danger': errors.stock_control}">
                                        <el-switch active-text="Si" v-model="form_control.stock_control" inactive-text="No" @change="update_stock()"></el-switch>
                                        <small class="form-control-feedback" v-if="errors.stock_control" v-text="errors.stock_control[0]"></small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col align-self-center" v-if="show_quotations=='1'">
                                <div class="form-group" :class="{'has-danger': errors.payment_condition_id}">
                                    <div class="form-group" :class="{'has-danger': errors.stock_control}">
                                      <el-button type="primary" icon="el-icon-search" @click="showDialogQuotations=true">Cotizaciones</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <template v-if="!is_client">
                            <!-- <div class="row mb-3" v-if="form.operation_type_id == '1001'">
                                <div class="col-lg-4">
                                    <div class="form-group" >
                                        <label class="control-label">Bienes y servicios sujetos a detracciones<span class="text-danger"> *</span></label>
                                        <el-select v-model="form.detraction.detraction_type_id" @change="changeDetractionType" filterable >
                                            <el-option v-for="option in detraction_types" :key="option.id" :value="option.id" :label="`${option.description} - ${option.percentage}%`"></el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group" >
                                        <label class="control-label">Método pago - Detracción<span class="text-danger"> *</span></label>
                                        <el-select v-model="form.detraction.payment_method_id"  filterable>
                                            <el-option v-for="option in cat_payment_method_types" :key="option.id" :value="option.id" :label="`${option.description}`"></el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="control-label">Cuenta bancaria<span class="text-danger"> *</span></label>
                                        <el-input v-model="form.detraction.bank_account" readonly> <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="form-group">
                                        <label class="control-label">T. Detracción<span class="text-danger"> *</span></label>
                                        <el-input v-model="form.detraction.amount" readonly> <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                    </div>
                                </div>
                            </div> -->

                            <div class="row">
                                <div class="col-lg-4" v-if="form.payment_condition_id === '02'">
                                    <table v-if="form.fee.length>0" style="width: 100%">
                                        <thead>
                                        <tr>
                                            <th class="pb-2 ">Fecha</th>
                                            <th class="pb-2 ">Monto</th>
                                            <th style="width: 50px">
                                                <a href="#" @click.prevent="clickAddFee"
                                                   class="text-center font-weight-bold text-info">[+Agregar]</a>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(row, index) in form.fee" :key="index">
                                            <td>
                                                <div class="form-group mb-2 mr-2">
                                                    <el-date-picker v-model="row.date" type="date"
                                                                    value-format="yyyy-MM-dd"
                                                                    format="dd/MM/yyyy"
                                                                    :clearable="false">
                                                    </el-date-picker>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group mb-2 mr-2">
                                                    <el-input v-model="row.amount"> <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                                </div>
                                            </td>
                                            <td class="series-table-actions text-center">
                                                <button type="button"
                                                        class="btn waves-effect waves-light btn-sm btn-danger"
                                                        v-if="index > 0"
                                                        @click.prevent="clickRemoveFee(index)">
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <!-- <template v-else>
                                        <el-checkbox v-model="enabled_payments" class=" font-weight-bold" @change="changeEnabledPayments">¿Habilitar pagos?</el-checkbox>
                                    </template> -->
                                </div>
                                <div class="col-lg-8" v-if="!is_receivable && form.payment_condition_id === '01'">

                                    <table>
                                        <thead>
                                        <tr width="100%">
                                            <th v-if="form.payments.length>0" class="pb-2 ">Método de pago</th>
                                            <th v-if="form.payments.length>0" class="pb-2 ">Destino</th>
                                            <th v-if="form.payments.length>0" class="pb-2 ">Referencia</th>
                                            <th v-if="form.payments.length>0" class="pb-2 ">Monto</th>
                                            <th width="15%"><a href="#" @click.prevent="clickAddPayment"
                                                               class="text-center font-weight-bold text-info">[+
                                                Agregar]</a></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(row, index) in form.payments" :key="index">
                                            <td>
                                                <div class="form-group mb-2 mr-2">
                                                    <el-select v-model="row.payment_method_type_id"
                                                               @change="changePaymentDestination(index)">
                                                        <el-option v-for="option in payment_method_types"
                                                                   :key="option.id" :value="option.id"
                                                                   :label="option.description"></el-option>
                                                    </el-select>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group mb-2 mr-2">
                                                    <el-select v-model="row.payment_destination_id" filterable>
                                                        <el-option v-for="option in payment_destinations"
                                                                   :key="option.id" :value="option.id"
                                                                   :label="option.description"></el-option>
                                                    </el-select>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group mb-2 mr-2">
                                                    <el-input v-model="row.reference"> <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-group mb-2 mr-2">
                                                    <!--form.total suplanto a row.payment -->
                                                    <el-input v-model="row.payment"> <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                                </div>
                                            </td>
                                            <td class="series-table-actions text-center">
                                                <button type="button"
                                                        class="btn waves-effect waves-light btn-sm btn-danger"
                                                        @click.prevent="clickCancel(index)">
                                                    <i class="fa fa-trash"></i>
                                                </button>
                                            </td>
                                            <br>
                                        </tr>
                                        </tbody>
                                    </table>


                                </div>

                                <div class="col-lg-4" v-if="prepayment_deduction">
                                    <div class="form-group">
                                        <label class="font-weight-bold control-label">
                                            Comprobantes anticipados
                                            <a href="#" @click.prevent="clickAddPrepayment"
                                               class="text-center font-weight-bold text-info">[+ Agregar]</a>
                                        </label>
                                        <table style="width: 100%">
                                            <tr v-for="(row,index) in form.prepayments" :key="index">
                                                <td>
                                                    <el-select v-model="row.document_id" filterable
                                                               @change="changeDocumentPrepayment(index)">
                                                        <el-option v-for="option in prepayment_documents"
                                                                   :key="option.id" :value="option.id"
                                                                   :label="option.description"></el-option>
                                                    </el-select>
                                                </td>
                                                <td>
                                                    <el-input v-model="row.amount" readonly> <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                                </td>
                                                <td align="right">

                                                    <button type="button"
                                                            class="btn waves-effect waves-light btn-sm btn-danger"
                                                            @click.prevent="clickRemovePrepayment(index)">
                                                        <i class="fa fa-trash"></i>
                                                    </button>
                                                    <!-- <a href="#" @click.prevent="clickRemovePrepayment" style="color:red">Remover</a> -->
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                                <!-- <div class="col-lg-4 mt-3" >
                                    <el-checkbox v-model="form.has_prepayment"><strong>¿Es un pago anticipado?</strong></el-checkbox>
                                </div> -->

                                <div class="col-lg-8 mt-2" v-if="isActiveBussinessTurn('hotel')">
                                    <a href="#" @click.prevent="clickAddDocumentHotel"
                                       class="text-center font-weight-bold text-info">[+ Datos personales para reserva
                                        de hospedaje]</a>
                                </div>
                                <div class="col-lg-8 mt-2" v-if="isActiveBussinessTurn('transport')">
                                    <a href="#" @click.prevent="clickAddDocumentTransport"
                                       class="text-center font-weight-bold text-info">[+ Datos para transporte de
                                        pasajeros]</a>
                                </div>
                            </div>

                        </template>

                        <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="control-label">Observación</label>

                                    <el-input type="textarea" autosize v-model="form.additional_information"> <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <el-collapse v-model="activePanel" accordion>
                                    <el-collapse-item name="1">
                                        <template slot="title">
                                            <i class="fa fa-plus text-info"></i> &nbsp; Información Adicional<i
                                            class="header-icon el-icon-information"></i>
                                        </template>
                                        <div class="row mt-2">


                                            <template v-if="!isActiveBussinessTurn('tap')">

                                                <template v-if="!is_client">

                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label class="control-label">
                                                                Guias
                                                                <a href="#" @click.prevent="clickAddGuide"
                                                                   class="text-center font-weight-bold text-info">[+
                                                                    Agregar]</a>
                                                            </label>
                                                            <table style="width: 100%">
                                                                <tr v-for="(guide,index) in form.guides">
                                                                    <td>
                                                                        <el-select v-model="guide.document_type_id">
                                                                            <el-option
                                                                                v-for="option in document_types_guide"
                                                                                :key="option.id" :value="option.id"
                                                                                :label="option.description"></el-option>
                                                                        </el-select>
                                                                    </td>
                                                                    <td>
                                                                        <el-input v-model="guide.number"> <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                                                    </td>
                                                                    <td align="right">
                                                                        <button type="button"
                                                                                class="btn waves-effect waves-light btn-sm btn-danger"
                                                                                @click.prevent="clickRemoveGuide(index)">
                                                                            <i class="fa fa-trash"></i>
                                                                        </button>
                                                                        <!-- <a href="#" @click.prevent="clickRemoveGuide" style="color:red">Remover</a> -->
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            <!--<el-input-->
                                                            <!--type="textarea"-->
                                                            <!--autosize-->
                                                            <!--v-model="form.additional_information">-->
                                                            <!-- <i slot="prefix" class="el-icon-edit-outline"></i></el-input>-->
                                                        </div>
                                                    </div>
                                                </template>

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label class="control-label">Observaciones</label>
                                                        <el-input
                                                            type="textarea"
                                                            autosize
                                                            v-model="form.additional_information">
                                                         <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group"
                                                         :class="{'has-danger': errors.purchase_order}">
                                                        <label class="control-label">Orden Compra</label>
                                                        <!-- <el-input v-model="form.purchase_order"> <i slot="prefix" class="el-icon-edit-outline"></i></el-input> -->
                                                        <el-input
                                                            type="textarea"
                                                            v-model="form.purchase_order">
                                                         <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                                        <small class="form-control-feedback"
                                                               v-if="errors.purchase_order"
                                                               v-text="errors.purchase_order[0]"></small>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>

                                                <template v-if="!is_client">

                                                    <div class="col-md-5">
                                                        <div class="form-group">
                                                            <label class="control-label">
                                                                Guias
                                                                <a href="#" @click.prevent="clickAddGuide"
                                                                   class="text-center font-weight-bold text-info">[+
                                                                    Agregar]</a>
                                                            </label>
                                                            <table style="width: 100%">
                                                                <tr v-for="(guide,index) in form.guides">
                                                                    <td>
                                                                        <el-select v-model="guide.document_type_id">
                                                                            <el-option
                                                                                v-for="option in document_types_guide"
                                                                                :key="option.id" :value="option.id"
                                                                                :label="option.description"></el-option>
                                                                        </el-select>
                                                                    </td>
                                                                    <td>
                                                                        <el-input v-model="guide.number"> <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                                                    </td>
                                                                    <td align="right">
                                                                        <button type="button"
                                                                                class="btn waves-effect waves-light btn-sm btn-danger"
                                                                                @click.prevent="clickRemoveGuide(index)">
                                                                            <i class="fa fa-trash"></i>
                                                                        </button>
                                                                        <!-- <a href="#" @click.prevent="clickRemoveGuide" style="color:red">Remover</a> -->
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                            <!--<el-input-->
                                                            <!--type="textarea"-->
                                                            <!--autosize-->
                                                            <!--v-model="form.additional_information">-->
                                                            <!-- <i slot="prefix" class="el-icon-edit-outline"></i></el-input>-->
                                                        </div>
                                                    </div>
                                                </template>

                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label class="control-label">Observaciones</label>
                                                        <el-input
                                                            type="textarea"
                                                            autosize
                                                            v-model="form.additional_information">
                                                         <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="form-group"
                                                         :class="{'has-danger': errors.purchase_order}">
                                                        <label class="control-label">Orden Compra</label>
                                                        <!-- <el-input v-model="form.purchase_order"> <i slot="prefix" class="el-icon-edit-outline"></i></el-input> -->
                                                        <el-input
                                                            type="textarea"
                                                            v-model="form.purchase_order">
                                                         <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                                        <small class="form-control-feedback"
                                                               v-if="errors.purchase_order"
                                                               v-text="errors.purchase_order[0]"></small>
                                                    </div>
                                                </div>

                                                <div class="col-md-2">
                                                    <div class="form-group"
                                                         :class="{'has-danger': errors.plate_number}">
                                                        <label class="control-label">N° Placa</label>
                                                        <!-- <el-input v-model="form.plate_number"> <i slot="prefix" class="el-icon-edit-outline"></i></el-input> -->
                                                        <el-input
                                                            type="textarea"
                                                            v-model="form.plate_number">
                                                         <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                                        <small class="form-control-feedback" v-if="errors.plate_number"
                                                               v-text="errors.plate_number[0]"></small>
                                                    </div>
                                                </div>
                                            </template>

                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                 <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th class="font-weight-bold">Afectar<br>Stock</th>
                                            <th class="font-weight-bold">Descripción</th>
                                            <th class="text-center font-weight-bold">Unidad</th>
                                            <th class="text-end font-weight-bold">Cantidad</th>
                                            <th class="text-end font-weight-bold">Precio Unitario</th>
                                            <th class="text-end font-weight-bold">Subtotal</th>
                                            <!--<th class="text-end font-weight-bold">Cargo</th>-->
                                            <th class="text-end font-weight-bold">Total</th>
                                            <th class="text-end font-weight-bold">
                                                <button type="button" class="btn waves-effect waves-light btn-primary btn-sm"
                                                        @click.prevent="clickAddItemInvoice">Agregar Producto
                                                </button>

                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody v-if="form.items.length > 0">
                                        <tr v-for="(row, index) in form.items" :key="index">
                                            <td>{{ index + 1 }}</td>
                                            <td>{{ row.item.is_stock }}</td>
                                            <td>
                                                <template v-if="row.name_product_pdf==null">
                                                    {{ row.item.description }}
                                                </template>
                                                <template v-else>
                                                    <div class="txt-info f-w-700" v-html="row.name_product_pdf"></div>
                                                </template>
                                                {{ row.item.presentation.hasOwnProperty('description') ? row.item.presentation.description : '' }}<br/><small>{{ row.affectation_igv_type.description }}</small>
                                            </td>
                                            <td class="text-center">{{ row.item.unit_type_id }}</td>

                                            <td class="text-end">
                                                <el-input-number :min="0.01" v-model="row.quantity" @change="calculateItem(index,row.quantity,row.unit_price)"></el-input-number>
                                            </td>
                                            <!--<td class="text-end" v-else ><el-input-number :min="0.01" v-model="row.quantity"></el-input-number> </td> -->

                                            <td class="text-end">{{ currency_type.symbol }}
                                                 <el-input-number :min="0.01" v-model="row.unit_price"  @change="calculateItem(index,row.quantity,row.unit_price)"></el-input-number>
                                                <!-- {{ getFormatUnitPriceRow(row.unit_price) }} -->
                                            </td>
                                            <!--<td class="text-end" v-else ><el-input-number :min="0.01" v-model="row.unit_price"></el-input-number> </td> -->


                                            <td class="text-end">{{ currency_type.symbol }} {{ row.total_value }}</td>
                                            <!--<td class="text-end">{{ currency_type.symbol }} {{ row.total_charge }}</td>-->
                                            <td class="text-end">{{ currency_type.symbol }} {{ row.total }}</td>
                                            <td class="text-end">
                                                <button type="button"
                                                        class="btn waves-effect waves-light btn-sm btn-danger"
                                                        @click.prevent="clickRemoveItem(index)">x
                                                </button>
                                                <button type="button"
                                                        class="btn waves-effect waves-light btn-sm btn-info"
                                                        @click="ediItem(row, index)"><span style='font-size:10px;'>&#9998;</span>
                                                </button>

                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>




                            <div class="col-md-12"
                                 style="display: flex; flex-direction: column; align-items: flex-end;">
                                <table>
                                    <tr v-if="form.total_taxed > 0 && enabled_discount_global">
                                        <td>
                                            DESCUENTO
                                            <template v-if="is_amount"> MONTO</template>
                                            <template v-else> %</template>
                                            <el-checkbox class="ml-1 mr-1" v-model="is_amount"
                                                         @change="changeTypeDiscount"></el-checkbox>

                                        </td>
                                        <td>:</td>
                                        <td class="text-end">
                                            <el-input class="input-custom" v-model="total_global_discount"
                                                      @input="calculateTotal"> <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                                        </td>
                                    </tr>
                                    <!-----
                                    <tr v-if="form.detraction.amount > 0">
                                        <td>M. DETRACCIÓN</td>
                                        <td>:</td>
                                        <td class="text-end">{{ currency_type.symbol }} {{ form.detraction.amount }}</td>
                                    </tr>
                                    ----->
                                    <tr v-if="form.total_exportation > 0">
                                        <td>OP.EXPORTACIÓN</td>
                                        <td>:</td>
                                        <td class="text-end">{{ currency_type.symbol }} {{
                                                form.total_exportation
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="form.total_free > 0">
                                        <td>OP.GRATUITAS</td>
                                        <td>:</td>
                                        <td class="text-end">{{ currency_type.symbol }} {{ form.total_free }}</td>
                                    </tr>
                                    <tr v-if="form.total_unaffected > 0">
                                        <td>OP.INAFECTAS</td>
                                        <td>:</td>
                                        <td class="text-end">{{ currency_type.symbol }} {{
                                                form.total_unaffected
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="form.total_exonerated > 0">
                                        <td>OP.EXONERADAS</td>
                                        <td>:</td>
                                        <td class="text-end">{{ currency_type.symbol }} {{
                                                form.total_exonerated
                                            }}
                                        </td>
                                    </tr>
                                    <tr v-if="form.total_taxed > 0">
                                        <td>OP.GRAVADA</td>
                                        <td>:</td>
                                        <td class="text-end">{{ currency_type.symbol }} {{ form.total_taxed }}</td>
                                    </tr>
                                    <tr v-if="form.total_prepayment > 0">
                                        <td>ANTICIPOS</td>
                                        <td>:</td>
                                        <td class="text-end">{{ currency_type.symbol }} {{
                                                form.total_prepayment
                                            }}
                                        </td>
                                    </tr>
                                    <!-- <tr v-if="form.total_discount > 0">
                                        <td>DESCUENTOS</td>
                                        <td>:</td>
                                        <td class="text-end">{{ currency_type.symbol }} {{ form.total_discount }}</td>
                                    </tr> -->
                                    <tr v-if="form.total_igv > 0">
                                        <td>IGV</td>
                                        <td>:</td>
                                        <td class="text-end">{{ currency_type.symbol }} {{ form.total_igv }}</td>
                                    </tr>

                                </table>

                                <template v-if="form.total > 0">
                                    <span class="text-end" v-if="form.total > 0"><b>TOTAL VENTA: </b>{{ currency_type.symbol }} {{ form.total }}</span>
                                </template>
                                <template v-if="form.total > 0">
                                    <span class="text-end" v-if="form.total > 0"><b>REDONDEO: </b>{{ currency_type.symbol }} {{form.total_rounded }}</span>
                                </template>
                                  <template v-if="form.total > 0">
                                    <h4 class="text-end" v-if="form.total > 0"><b>TOTAL A PAGAR: </b>{{ currency_type.symbol }}{{form.total_payment }}</h4>
                                </template>
                            </div>

                        </div>

                    </div>


                    <div class="form-actions text-end pt-2 pb-2">
                        <el-button @click.prevent="close()">Cancelar</el-button>
                        <el-button class="submit" type="primary" native-type="submit" :loading="loading_submit"
                                   v-if="form.items.length > 0 && this.dateValid">Generar
                        </el-button>
                    </div>
                </form>
            </div>

            <document-form-item :showDialog.sync="showDialogAddItem"
                                :recordItem="recordItem"
                                :restringir_stock="restringir_stock"
                                :isEditItemNote="false"
                                :operation-type-id="form.operation_type_id"
                                :currency-type-id-active="form.currency_type_id"
                                :exchange-rate-sale="form.exchange_rate_sale"
                                :typeUser="typeUser"
                                :configuration="configuration"
                                :editNameProduct="configuration.edit_name_product"
                                :percentage_igv="percentage_igv"
                                @add="addRow"></document-form-item>

            <person-form :showDialog.sync="showDialogNewPerson"
                         type="customers"
                         :external="true"
                         :user_id="form.user_id"
                         :input_person="input_person"
                         :document_type_id="form.document_type_id"></person-form>

            <document-options :showDialog.sync="showDialogOptions"
                              :recordId="documentNewId"
                              :editDocument="editDocument"
                              :isContingency="is_contingency"
                              :showClose="showClose"></document-options>



        </div>
    </div>
</template>

<style>
.input-custom {
    width: 50% !important;
}
.el-textarea__inner {
    height: 65px !important;
    min-height: 65px !important;
}
</style>
<script>
import DocumentFormItem from './partials/item.vue'
import PersonForm from '../persons/form.vue'
import DocumentOptions from '../documents/partials/options.vue'
import {functions, exchangeRate} from '../../mixins/functions'
import {calculateRowItem} from '../../helpers/functions'
import Logo from '../companies/logo.vue'

export default {

    props: ['id', 'typeUser', 'configuration'],
    components: {
        DocumentFormItem,
        PersonForm,
        DocumentOptions,
        Logo,

    },
    mixins: [functions, exchangeRate],
    data() {
        return {

            datEmision: {
                disabledDate(time) {
                    return time.getTime() > moment();
                }
            },
            editDocument:false,
            show_restriction:false,
            show_quotations:false,
            dateValid: false,
            restringir_stock:false,
            input_person: {},
            user_default: null,
            showDialogDocumentDetraction: false,
            showDialogQuotations:false,
            has_data_detraction: false,
            showDialogFormHotel: false,
            showDialogFormTransport: false,
            is_client: false,
            recordItem: null,
            resource: 'documents',
            showDialogAddItem: false,
            showDialogNewPerson: false,
            showDialogOptions: false,
            loading_submit: false,
            loading_form: false,
            errors: {},
            form: {},
            form2: {},
            form_control:{},
            document_types: [],
            currency_types: [],
            discount_types: [],
            charges_types: [],
            all_customers: [],
            business_turns: [],
            form_payment: {},
            document_types_guide: [],
            customers: [],
            company: null,
            document_type_03_filter: null,
            operation_types: [],
            establishments: [],
            payment_method_types: [],
            establishment: null,
            all_series: [],
            series: [],
            prepayment_documents: [],
            currency_type: {},
            documentNewId: null,
            prepayment_deduction: false,
            activePanel: 0,
            total_global_discount: 0,
            loading_search: false,
            is_amount: true,
            enabled_discount_global: false,
            user: null,
            array_users: [],
            is_receivable: false,
            is_contingency: false,
            cat_payment_method_types: [],
            select_first_document_type_03: false,
            detraction_types: [],
            all_detraction_types: [],
            customer_addresses: [],
            payment_destinations: [],
            validarForm: false,
            payment_conditions: [],
            redondeo:null,
            percentage_igv:null,
            showClose:false,
                loader: false,
                text_loader: 'Espere porfavor....'
        }
    },


    async created() {
            qz.security.setCertificatePromise((resolve, reject) => {
                this.$http.get('/api/qz/crt/override', {
                    responseType: 'text'
                }).then(response => {
                     resolve(response.data);
                }).catch(error => {
                    reject(error.data);
                });
            });
            qz.security.setSignaturePromise((toSign) => {
                return (resolve, reject) => {
                    this.$http.post('/api/qz/signing', {request: toSign})
                        .then(response => {
                            console.log('res2');
                            resolve(response.data);
                        }).catch(error => {
                        reject(error.data);
                    });
                };
            });

        await this.initForm()
         this.loader=true
        await this.$http.get(`/documents/tables`) .then(response => {
                this.show_restriction=response.data.configuration.show_restriction;
                this.show_quotations=response.data.configuration.show_quotations;
                this.document_types = response.data.document_types_invoice;
                this.document_types_guide = response.data.document_types_guide;
                this.currency_types = response.data.currency_types
                this.business_turns = response.data.business_turns
                this.establishments = response.data.establishments
                this.operation_types = response.data.operation_types
                this.all_series = response.data.series
                this.all_customers = response.data.customers
                this.discount_types = response.data.discount_types
                this.charges_types = response.data.charges_types
                this.payment_method_types = response.data.payment_method_types
                this.enabled_discount_global = response.data.enabled_discount_global
                this.company = response.data.company;
                this.user = response.data.user;
                this.array_users = response.data.users;
                this.document_type_03_filter = response.data.document_type_03_filter;
                this.select_first_document_type_03 = response.data.select_first_document_type_03

                this.form.currency_type_id = (this.currency_types.length > 0) ? this.currency_types[0].id : null;
                this.form.establishment_id = (this.establishments.length > 0) ? this.establishments[0].id : null;
                this.form.document_type_id = (this.document_types.length > 0) ? this.document_types[0].id : null;
                this.form.operation_type_id = (this.operation_types.length > 0) ? this.operation_types[0].id : null;
                // this.prepayment_documents = response.datta.prepayment_documents;
                this.is_client = response.data.is_client;
                this.payment_conditions = response.data.payment_conditions;
                // this.cat_payment_method_types = response.data.cat_payment_method_types;
                // this.all_detraction_types = response.data.detraction_types;
                this.payment_destinations = response.data.payment_destinations
                this.user_default = response.data.user_select.id;
                this.form.user_id = response.data.user_select.id;

                this.selectDocumentType()
                this.changeEstablishment()
                this.changeDateOfIssue()
                this.filterCustomers()
                this.changeDocumentType()
                this.changeCurrencyType()
                this.initInputPerson()

                this.isUpdate()

                this.reloadDataCustomers(this.form.customer_id)
            })
        //await this.getPercentageIgv();
        this.loading_form = true
        this.$eventHub.$on('reloadDataPersons', (customer_id) => {
            this.reloadDataCustomers(customer_id)
        })
        this.$eventHub.$on('initInputPerson', () => {
            this.initInputPerson()
        })
         this.loader=false
        if(this.show_restriction==1){
        this.form_control = {
            id: null,
            stock_control: false,
        };
        await this.$http.get(`/inventories/configuration/record`) .then(response => {
            if (response.data !== '') this.form_control = response.data.data;
            this.restringir_stock=response.data.data.stock_control
        });
        }
        let form_data={
            establishment_id:this.form.establishment_id,
            date: moment().format('YYYY-MM-DD'),
        }
        const response= await this.$http.post('/get_igv',form_data)
        this.percentage_igv= response.data
        console.log("this.percentage_igv",this.percentage_igv)
    },

    methods: {

        update_stock(){
              this.$http.post(`/inventories/configuration`, this.form_control).then(response => {
                    if (response.data.success) {
                        this.$message.success(response.data.message);
                        this.restringir_stock=this.form_control.stock_control
                    }
                    else {
                        this.$message.error(response.data.message);
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                    else {
                        console.log(error);
                    }
                }).then(() => {
                    this.loading_submit = false;
                });
        },
         async print_pdf(PrinterName, FileLink){
              try {
                let config = qz.configs.create(PrinterName, {scaleContent : false});
                    if (!qz.websocket.isActive()) {
                        await qz.websocket.connect(config);
                    }
                    let data = [
                        {
                            type: 'pdf',
                            format: 'file',
                            data: FileLink
                        }
                    ];
                    qz.print(config, data).catch((e) => {
                        this.$message.error(e.message);
                    });
                    this.$message.success("se esta imprimiendo el comprobante con exito");
                    location.href = `/${this.resource}`

                } catch (e) {
                    this.$message.error(e.message);
                }
        },
        async isUpdate() {

            if (this.id) {
                this.editDocument=true
                await this.$http.get(`/${this.resource}/record2/${this.id}`)
                    .then(response => {
                        this.form = {
                            user_id:response.data.data.user_id,
                            id: response.data.data.id,
                            establishment_id: response.data.data.establishment_id,
                            document_type_id: response.data.data.document_type_id,
                            series_id: response.data.data.series_id,
                            number: response.data.data.number,
                            date_of_issue:response.data.data.date_of_issue,
                            time_of_issue: response.data.data.time_of_issue,
                            customer_id: response.data.data.customer_id,
                            currency_type_id: response.data.data.currency_type_id,
                            purchase_order: response.data.data.purchase_order,
                            exchange_rate_sale:response.data.data.exchange_rate_sale,
                            total_prepayment:response.data.data.total_prepayment,
                            total_charge: response.data.data.total_charge,
                            total_discount: response.data.data.total_discount,
                            total_exportation: response.data.data.total_exportation,
                            total_free:response.data.data.total_free,
                            total_taxed: response.data.data.total_taxed,
                            total_unaffected: response.data.data.total_unaffected,
                            total_exonerated:response.data.data.total_exonerated,
                            total_igv: response.data.data.total_igv,
                            total_base_isc: response.data.data.total_base_isc,
                            total_isc:response.data.data.total_isc,
                            total_base_other_taxes: response.data.data.total_base_other_taxes,
                            total_other_taxes: response.data.data.total_other_taxes,
                            total_plastic_bag_taxes:response.data.data.total_plastic_bag_taxes,
                            total_taxes: response.data.data.total_taxes,
                            total_value:response.data.data.total_value,
                            total_rounded:response.data.data.total_rounded,
                            total_payment:response.data.data.total_payment,
                            total:response.data.data.total,
                            operation_type_id: response.data.data.operation_type_id,
                            date_of_due: response.data.data.date_of_due,
                            afectar_caja:response.data.data.afectar_caja,
                            items: response.data.data.items,
                            charges: [],
                            discounts: [],
                            attributes: [],
                            guides: [],
                            payments: response.data.data.payments,
                            prepayments: [],
                            legends: [],
                            detraction: {},
                            additional_information: response.data.data.additional_information[0],
                            plate_number: null,
                            has_prepayment: false,
                            affectation_type_prepayment: null,
                            actions: {
                                format_pdf: 'a4',
                            },
                            hotel: {},
                            transport: {},
                            customer_address_id: null,
                             payment_condition_id: response.data.data.payment_condition_id,
                            fee: []
                        }
                        // if(this.form.payment_condition_id=="01"){
                        //     this.form.afectar_caja=true
                        // }else{
                        //        this.form.afectar_caja=false
                        // }

                        let item_save=this.form.items
                        if(this.form.payments.length>0){
                            this.form.payments[0].payment_destination_id=this.payment_destinations[0].id
                        }
                        this.generar_items(item_save)
                        this.filterSeries();
                        this.reloadDataCustomers(this.form.customer_id)
                        this.filterCustomers()
                        this.changeCustomer()
                        this.showClose=true
                    })
                //     this.filterCustomers()

            }

        },
        generar_items(item){
            for (let index = 0; index < item.length; index++) {
                this.form.items[index].additional_information=null
                this.form.items[index].attributes=[]
                this.form.items[index].charges=[]
                this.form.items[index].discounts=[]
            }
         },
        teclasInit() {
            document.onkeydown = (e) => {
                const key = e.key;
                if (key == "F4") {
                    this.$nextTick(() => {
                        let input = this.$refs.cliente;
                        input.focus();
                        return false;
                    })
                }
                if (key == "F5") { //Agregar cliente
                    this.showDialogNewPerson = true;
                    return false;
                }
                if (key == "F6") {//Agregar Producto
                    this.clickAddItemInvoice()
                    return false;
                }
                if (key == "F7" && this.form.items.length > 0) {//Guardar
                    this.submit()
                }
                if (key == "F8") {//Cancelar
                    this.close()
                }
            }
        },

        changePaymentDestination(index) {
            // if(this.form.payments[index].payment_method_type_id=='01'){
            //     this.payment_destinations = this.cash
            // }else{
            //     this.payment_destinations = this.payment_destinations
            // }
            if (this.form.payments[0].payment_method_type_id != "01") {
                this.form.payments[0].payment = 0.00
            } else {
                this.form.payments[0].payment = this.form.total
            }
        },
        selectDocumentType() {
            this.form.document_type_id = (this.select_first_document_type_03) ? '03' : '01'
        },
        keyupCustomer() {

            if (this.input_person.number) {

                if (!isNaN(parseInt(this.input_person.number))) {

                    switch (this.input_person.number.length) {
                        case 8:
                            this.input_person.identity_document_type_id = '1'
                            this.showDialogNewPerson = true
                            break;

                        case 11:
                            this.input_person.identity_document_type_id = '6'
                            this.showDialogNewPerson = true
                            break;
                        default:
                            this.input_person.identity_document_type_id = '6'
                            this.showDialogNewPerson = true
                            break;
                    }
                }
            }
        },
        addDocumentDetraction(detraction) {

            this.form.detraction = detraction

            // this.has_data_detraction = (detraction.pay_constancy || detraction.detraction_type_id || detraction.payment_method_id || (detraction.amount && detraction.amount >0)) ? true:false
            this.has_data_detraction = (detraction) ? detraction.has_data_detraction : false


        },
        clickAddItemInvoice: function () {
            this.recordItem = null
            this.showDialogAddItem = true
        },
        getFormatUnitPriceRow(unit_price) {
            return _.round(unit_price, 6)
            // return unit_price.toFixed(6)
        },
        discountGlobalPrepayment() {

            let global_discount = 0
            this.form.prepayments.forEach((item) => {
                global_discount += parseFloat(item.amount)
            })

            let base = (this.form.affectation_type_prepayment == 10) ? parseFloat(this.form.total_taxed) : parseFloat(this.form.total_exonerated)
            let amount = _.round(parseFloat(global_discount), 2)
            let factor = _.round(amount / base, 4)

            this.form.total_prepayment = _.round(global_discount, 2)

            if (this.form.affectation_type_prepayment == 10) {


                let discount = _.find(this.form.discounts, {'discount_type_id': '04'})

                if (global_discount > 0 && !discount) {


                    this.form.total_discount = _.round(amount, 2)
                    this.form.total_taxed = _.round(base - amount, 2)
                    this.form.total_value = _.round(base - amount, 2)
                    this.form.total_igv = _.round(this.form.total_value * (this.percentage_igv/100), 2)
                    this.form.total_taxes = _.round(this.form.total_igv, 2)
                    this.form.total = _.round(this.form.total_value + this.form.total_taxes, 2)

                    this.form.discounts.push({
                        discount_type_id: "04",
                        description: "Descuentos globales por anticipos gravados que afectan la base imponible del IGV/IVAP",
                        factor: factor,
                        amount: amount,
                        base: base
                    })

                } else {

                    let pos = this.form.discounts.indexOf(discount);

                    if (pos > -1) {

                        this.form.total_discount = _.round(amount, 2)
                        this.form.total_taxed = _.round(base - amount, 2)
                        this.form.total_value = _.round(base - amount, 2)
                        this.form.total_igv = _.round(this.form.total_value * (this.percentage_igv/100), 2)
                        this.form.total_taxes = _.round(this.form.total_igv, 2)
                        this.form.total = _.round(this.form.total_value + this.form.total_taxes, 2)

                        this.form.discounts[pos].base = base
                        this.form.discounts[pos].amount = amount
                        this.form.discounts[pos].factor = factor

                    }

                }

            } else {

                let exonerated_discount = _.find(this.form.discounts, {'discount_type_id': '05'})


                this.form.total_discount = _.round(amount, 2)
                this.form.total_exonerated = _.round(base - amount, 2)
                this.form.total_value = this.form.total_exonerated
                this.form.total = this.form.total_value

                if (global_discount > 0 && !exonerated_discount) {


                    this.form.discounts.push({
                        discount_type_id: '05',
                        description: 'Descuentos globales por anticipos exonerados',
                        factor: factor,
                        amount: amount,
                        base: base
                    })

                } else {

                    let position = this.form.discounts.indexOf(exonerated_discount);

                    if (position > -1) {

                        this.form.discounts[position].base = base
                        this.form.discounts[position].amount = amount
                        this.form.discounts[position].factor = factor

                    }

                }
            }

        },
        async changeDocumentPrepayment(index) {

            let prepayment = await _.find(this.prepayment_documents, {id: this.form.prepayments[index].document_id})

            this.form.prepayments[index].number = prepayment.description
            this.form.prepayments[index].document_type_id = prepayment.document_type_id
            this.form.prepayments[index].amount = prepayment.amount
            this.form.prepayments[index].total = prepayment.total

            await this.changeTotalPrepayment()


        },
        clickAddPrepayment() {
            this.form.prepayments.push({
                document_id: null,
                number: null,
                document_type_id: null,
                amount: 0,
                total: 0,
            });

            this.changeTotalPrepayment()
        },
        clickRemovePrepayment(index) {

            this.form.prepayments.splice(index, 1)
            this.changeTotalPrepayment()
            if (this.form.prepayments.length == 0)
                this.deletePrepaymentDiscount()

        },
        async changePrepaymentDeduction() {

            this.form.prepayments = []
            this.form.total_prepayment = 0
            await this.deletePrepaymentDiscount()

            if (this.prepayment_deduction) {

                await this.initialValueATPrepayment()
                await this.changeTotalPrepayment()
                await this.getDocumentsPrepayment()

            }
            // else{

            // this.form.total_prepayment = 0
            // await this.deletePrepaymentDiscount()

            // }

        },
        initialValueATPrepayment() {
            this.form.affectation_type_prepayment = (!this.form.affectation_type_prepayment) ? 10 : this.form.affectation_type_prepayment
        },
        cleanValueATPrepayment() {
            this.form.affectation_type_prepayment = null
        },
        changeHasPrepayment() {

            if (this.form.has_prepayment) {
                this.initialValueATPrepayment()
            } else {
                this.cleanValueATPrepayment()
            }

        },
        async changeAffectationTypePrepayment() {

            await this.initialValueATPrepayment()

            if (this.prepayment_deduction) {

                this.form.total_prepayment = 0
                await this.deletePrepaymentDiscount()
                await this.changePrepaymentDeduction()
            }

        },
        async deletePrepaymentDiscount() {

            let discount = await _.find(this.form.discounts, {'discount_type_id': '04'})
            let discount_exonerated = await _.find(this.form.discounts, {'discount_type_id': '05'})

            let pos = this.form.discounts.indexOf(discount)
            if (pos > -1) {

                this.form.discounts.splice(pos, 1)
                this.changeTotalPrepayment()
            }

            let pos_exonerated = this.form.discounts.indexOf(discount_exonerated)
            if (pos_exonerated > -1) {
                this.form.discounts.splice(pos_exonerated, 1)
                this.changeTotalPrepayment()
            }
        },
        getDocumentsPrepayment() {
            this.$http.get(`/${this.resource}/prepayments/${this.form.affectation_type_prepayment}`).then((response) => {
                this.prepayment_documents = response.data
            })
        },
        changeTotalPrepayment() {
            this.calculateTotal()
        },
        isActiveBussinessTurn(value) {
            return (_.find(this.business_turns, {'value': value})) ? true : false
        },
        clickAddDocumentHotel() {
            this.showDialogFormHotel = true
        },
        clickAddDocumentTransport() {
            this.showDialogFormTransport = true
        },

        addDocumentHotel(hotel) {
            this.form.hotel = hotel

        },
        addDocumentTransport(transport) {
            this.form.transport = transport

        },
        changeIsReceivable() {

        },
        clickAddPayment() {
            this.form.payments.push({
                id: null,
                document_id: null,
                date_of_payment: moment().format('YYYY-MM-DD'),
                payment_method_type_id: '01',
                reference: null,
                payment_destination_id: 'cash',
                payment: 0,

            });
        },
        clickCancel(index) {
            this.form.payments.splice(index, 1);
        },
        ediItem(row, index) {
            row.indexi = index
            this.recordItem = row
            this.showDialogAddItem = true
        },

        searchRemoteCustomers(input) {

            if (input.length > 0) {
                // if (input!="") {

                this.loading_search = true
                let parameters = `input=${input}&document_type_id=${this.form.document_type_id}&operation_type_id=${this.form.operation_type_id}&seller_id=${this.form.user_id}`
                this.$http.get(`/${this.resource}/search/customers?${parameters}`)
                    .then(response => {
                        this.customers = response.data.customers
                        this.loading_search = false
                        this.input_person.number = null

                        if (this.customers.length == 0) {
                            this.filterCustomers()
                            this.input_person.number = input
                        }
                    })
            } else {
                // this.customers = []
                this.filterCustomers()
                this.input_person.number = null
            }

        },
        initForm() {
            this.errors = {}
            this.form = {
                id: null,
                orden_id:null,
                printerOn:false,
                generar_document:"Si",
                restaurant:false,
                afectar_caja:false,
                establishment_id: null,
                document_type_id: null,
                series_id: null,
                number: '#',
                date_of_issue: moment().format('YYYY-MM-DD'),
                time_of_issue: moment().format('HH:mm:ss'),
                customer_id: null,
                currency_type_id: null,
                purchase_order: null,
                exchange_rate_sale: 1,
                total_prepayment: 0,
                total_charge: 0,
                total_discount: 0,
                total_exportation: 0,
                total_free: 0,
                total_taxed: 0,
                total_unaffected: 0,
                total_exonerated: 0,
                total_igv: 0,
                total_base_isc: 0,
                total_isc: 0,
                total_base_other_taxes: 0,
                total_other_taxes: 0,
                total_plastic_bag_taxes: 0,
                total_taxes: 0,
                total_value: 0,
                total: 0,
                total_rounded:0,
                total_payment:0,
                operation_type_id: null,
                date_of_due: moment().format('YYYY-MM-DD'),
                items: [],
                charges: [],
                discounts: [],
                attributes: [],
                guides: [],
                payments: [],
                prepayments: [],
                legends: [],
                detraction: {},
                additional_information: null,
                plate_number: null,
                has_prepayment: false,
                affectation_type_prepayment: null,
                actions: {
                    format_pdf: 'a4',
                },
                hotel: {},
                transport: {},
                customer_address_id: null,
                user_id: null,
                observation: null,
                payment_condition_id: '01',
                method_pay:'Efectivo',
                fee: []

            }

            this.clickAddPayment()

            this.changePaymentCondition();

            this.clickAddInitGuides()
            this.is_receivable = false
            this.total_global_discount = 0
            this.is_amount = true
            this.prepayment_deduction = false
            this.imageDetraction = {}
            this.$eventHub.$emit('eventInitForm')

            this.initInputPerson()

            if (!this.configuration.restrict_receipt_date) {
                this.datEmision = {}
            }
        },
        initInputPerson() {
            this.input_person = {
                number: null,
                identity_document_type_id: null
            }
        },
        resetForm() {
            this.activePanel = 0
            this.initForm()
            this.form.currency_type_id = (this.currency_types.length > 0) ? this.currency_types[0].id : null
            this.form.establishment_id = (this.establishments.length > 0) ? this.establishments[0].id : null
            this.form.document_type_id = (this.document_types.length > 0) ? this.document_types[0].id : null
            this.form.operation_type_id = (this.operation_types.length > 0) ? this.operation_types[0].id : null
            this.selectDocumentType()
            this.changeEstablishment()
            this.changeDocumentType()
            this.changeDateOfIssue()
            this.changeCurrencyType()
            this.form.user_id = this.user_default;
            this.form.exchange_rate_sale = 1
            this.filterCustomers();
            // this.filterCustomersUser();
        },
        async changeOperationType() {
            await this.filterCustomers();
            await this.setDataDetraction();
        },
        // async filterDetractionTypes(){
        //     this.detraction_types =  await _.filter(this.all_detraction_types, {'operation_type_id':this.form.operation_type_id})
        // },
        async setDataDetraction() {

            if (this.form.operation_type_id === '1001') {

                this.showDialogDocumentDetraction = true

                // this.$message.warning('Sujeta a detracción');
                // await this.filterDetractionTypes();
                let legend = await _.find(this.form.legends, {'code': '2006'})
                if (!legend) this.form.legends.push({code: '2006', value: 'Operación sujeta a detracción'})
                this.form.detraction.bank_account = this.company.detraction_account

            } else {

                _.remove(this.form.legends, {'code': '2006'})
                this.form.detraction = {}

            }
        },
        async changeDetractionType() {
            // let detraction_type = await _.find(this.detraction_types, {'id':this.form.detraction.detraction_type_id})

            if (this.form.detraction) {
                this.form.detraction.amount = (this.form.currency_type_id == 'PEN') ? _.round(parseFloat(this.form.total) * (parseFloat(this.form.detraction.percentage) / 100), 2) : _.round((parseFloat(this.form.total) * this.form.exchange_rate_sale) * (parseFloat(this.form.detraction.percentage) / 100), 2)
            }
        },
        validateDetraction() {

            if (this.form.operation_type_id === '1001') {
                let detraction = this.form.detraction
                let tot = (this.form.currency_type_id == 'PEN') ? this.form.total : (this.form.total * this.form.exchange_rate_sale)


                if (tot <= 700)
                    return {
                        success: false,
                        message: 'El importe de la operación debe ser mayor a S/ 700.00 o equivalente en USD'
                    }

                if (!detraction.detraction_type_id)
                    return {success: false, message: 'El campo bien o servicio sujeto a detracción es obligatorio'}

                if (!detraction.payment_method_id)
                    return {success: false, message: 'El campo método de pago - detracción es obligatorio'}

                if (!detraction.bank_account)
                    return {success: false, message: 'El campo cuenta bancaria es obligatorio'}

                if (detraction.amount <= 0)
                    return {success: false, message: 'El campo total detracción debe ser mayor a cero'}

            }

            return {success: true}

        },
        changeEstablishment() {
            this.establishment = _.find(this.establishments, {'id': this.form.establishment_id})
            this.filterSeries()
        },
        changeDocumentType() {
            this.filterSeries();
            this.cleanCustomer();
            this.filterCustomers();
            // this.filterCustomersUser();
        },
        cleanCustomer() {
            this.form.customer_id = null
            // this.customers = []
        },
        changeDateOfIssue() {
            if (moment(this.form.date_of_issue) < moment().day(-1) && this.configuration.restrict_receipt_date) {
                this.$message.error('No puede seleccionar una fecha menor a 6 días.');
                this.dateValid = false
            } else {
                this.dateValid = true
            }
            this.form.date_of_due = this.form.date_of_issue
            // this.searchExchangeRateByDate(this.form.date_of_issue).then(response => {
            //     this.form.exchange_rate_sale = response
            // })
            this.form.exchange_rate_sale = 1
        },
        assignmentDateOfPayment() {
            this.form.payments.forEach((payment) => {
                payment.date_of_payment = this.form.date_of_issue
            })
        },

        filterSeries() {
            this.form.series_id = null
            this.series = _.filter(this.all_series, {
                'establishment_id': this.form.establishment_id,
                'document_type_id': this.form.document_type_id,
                'contingency': this.is_contingency
            });
            this.form.series_id = (this.series.length > 0) ? this.series[0].id : null
        },
        filterCustomersUser() {
            this.form.customer_id = null
            let customer_alll = []
            this.customer_addresses = []

            if (this.form.document_type_id == "01") {
                customer_alll = _.filter(this.all_customers, {
                    'seller_id': this.form.user_id,
                    'identity_document_type_id': '6'
                })
            } else if (this.form.document_type_id == "03") {
                customer_alll = _.filter(this.all_customers, {
                    'seller_id': this.form.user_id,
                    'identity_document_type_id': '1'
                })
            }

            this.customers = customer_alll

        },
        filterCustomers() {
            // this.form.customer_id = null

            if (this.form.operation_type_id === '0101' || this.form.operation_type_id === '1001') {

                if (this.form.document_type_id === '01') {

                    let customers_alll = _.filter(this.all_customers, {
                        'identity_document_type_id': '6',
                        'seller_id': this.form.user_id
                    })
                    this.customers = customers_alll

                } else {
                    if (this.document_type_03_filter) {
                        this.customers = _.filter(this.all_customers, (c) => {
                            return c.identity_document_type_id !== '6'
                        })
                    } else {
                        this.customers = this.all_customers
                    }
                }
            } else {
                this.customers = this.all_customers
            }

        },
        clickAddInitGuides() {
            this.form.guides.push({
                document_type_id: '09',
                number: null
            }, {
                document_type_id: '31',
                number: null
            })
        },
        clickAddGuide() {
            this.form.guides.push({
                document_type_id: null,
                number: null
            })
        },
        clickRemoveGuide(index) {
            this.form.guides.splice(index, 1)
        },
        addRow(row) {
            if (this.recordItem) {
                //this.form.items.$set(this.recordItem.indexi, row)
                this.form.items[this.recordItem.indexi] = row
                this.recordItem = null
            } else {
                this.form.items.push(JSON.parse(JSON.stringify(row)));
            }
            this.calculateTotal();
            if(this.form.fee.length>0){
            this.form.fee[0].amount=this.form.total
            }
            this.calculateFee();


        },
        clickRemoveItem(index) {
            this.form.items.splice(index, 1)
            this.calculateTotal()
        },
        changeCurrencyType() {
            this.currency_type = _.find(this.currency_types, {'id': this.form.currency_type_id})
            let items = []
            this.form.items.forEach((row) => {
                items.push(calculateRowItem(row, this.form.currency_type_id, this.form.exchange_rate_sale))
            });
            this.form.items = items
            this.calculateTotal()
        },
        calculateTotal() {
            let total_discount = 0
            let total_charge = 0
            let total_exportation = 0
            let total_taxed = 0
            let total_exonerated = 0
            let total_unaffected = 0
            let total_free = 0
            let total_igv = 0
            let total_value = 0
            let total = 0
            let total_plastic_bag_taxes = 0
            this.form.items.forEach((row) => {
                total_discount += parseFloat(row.total_discount)
                total_charge += parseFloat(row.total_charge)

                if (row.affectation_igv_type_id === '10') {
                    total_taxed += parseFloat(row.total_value)
                }
                if (row.affectation_igv_type_id === '20') {
                    total_exonerated += parseFloat(row.total_value)
                }
                if (row.affectation_igv_type_id === '30') {
                    total_unaffected += parseFloat(row.total_value)
                }
                if (row.affectation_igv_type_id === '40') {
                    total_exportation += parseFloat(row.total_value)
                }
                if (['10', '20', '30', '40'].indexOf(row.affectation_igv_type_id) < 0) {
                    total_free += parseFloat(row.total_value)
                }
                if (['10', '20', '30', '40'].indexOf(row.affectation_igv_type_id) > -1) {
                    total_igv += parseFloat(row.total_igv)
                    console.log("parseFloat(row.total_igv)",parseFloat(row.total_igv))
                    total += parseFloat(row.total)
                }
                total_value += parseFloat(row.total_value)
                total_plastic_bag_taxes += parseFloat(row.total_plastic_bag_taxes)
            });

            this.form.total_exportation = _.round(total_exportation, 2)
            this.form.total_taxed = _.round(total_taxed, 2)
            this.form.total_exonerated = _.round(total_exonerated, 2)
            this.form.total_unaffected = _.round(total_unaffected, 2)
            this.form.total_free = _.round(total_free, 2)
            this.form.total_igv = _.round(total_igv, 2)
            this.form.total_value = _.round(total_value, 2)
            this.form.total_taxes = _.round(total_igv, 2)
            this.form.total_plastic_bag_taxes = _.round(total_plastic_bag_taxes, 2)
            // this.form.total = _.round(total, 2)
            this.form.total = _.round(total + this.form.total_plastic_bag_taxes, 2)

            if (this.enabled_discount_global)
                this.discountGlobal()

            if (this.prepayment_deduction)
                this.discountGlobalPrepayment()

            if (this.form.operation_type_id === '1001')
                this.changeDetractionType()

            this.setTotalDefaultPayment()
            this.form.total_rounded= Math.abs(Math.round(parseFloat(this.form.total)* 10) / 10 -this.form.total).toFixed(2)
            this.form.total_payment=Math.round(parseFloat(this.form.total)* 10) / 10
        },
        setTotalDefaultPayment() {

            if (this.form.payments.length > 0) {

                this.form.payments[0].payment = this.form.total
            }
        },
        changeTypeDiscount() {
            this.calculateTotal()
        },
        discountGlobal() {

            let base = this.form.total_taxed

            let amount = (this.is_amount) ? parseFloat(this.total_global_discount) : parseFloat(this.total_global_discount) / 100 * base
            let factor = (this.is_amount) ? _.round(amount / base, 5) : _.round(parseFloat(this.total_global_discount) / 100, 5)

            if (this.total_global_discount > 0 && this.form.discounts.length == 0) {

                this.form.discounts.push({
                    discount_type_id: "02",
                    description: "Descuento Global afecta a la base imponible",
                    factor: 0,
                    amount: 0,
                    base: 0
                })

            }


            if (this.form.discounts.length) {

                this.form.total_discount = _.round(amount, 2)
                this.form.total_value = _.round(base - amount, 2)
                this.form.total_igv = _.round(this.form.total_value * (this.percentage_igv/100), 2)
                this.form.total_taxes = _.round(this.form.total_igv, 2)
                this.form.total = _.round(this.form.total_value + this.form.total_taxes, 2)

                this.form.total_taxed = this.form.total_value

                this.form.discounts[0].base = base
                this.form.discounts[0].amount = _.round(amount, 2)
                this.form.discounts[0].factor = factor
            }



        },
        async deleteInitGuides() {
            //eliminando guias null
            await _.remove(this.form.guides, {'number': null})

        },
        async asignPlateNumberToItems() {

            if (this.form.plate_number) {

                await this.form.items.forEach(item => {

                    let at = _.find(item.attributes, {'attribute_type_id': '5010'})

                    if (!at) {
                        item.attributes.push({
                            attribute_type_id: '5010',
                            description: "Numero de Placa",
                            value: this.form.plate_number,
                            start_date: null,
                            end_date: null,
                            duration: null,
                        })
                    }

                });

            }
        },
        async validateAffectationTypePrepayment() {

            let not_equal_affectation_type = 0

            await this.form.items.forEach(item => {
                if (item.affectation_igv_type_id != this.form.affectation_type_prepayment) {
                    not_equal_affectation_type++
                }
            });

            return {
                success: (not_equal_affectation_type > 0) ? false : true,
                message: 'Los items deben tener tipo de afectación igual al seleccionado en el anticipo'
            }
        },

        async submit() {

            if (this.form.has_prepayment || this.prepayment_deduction) {
                let error_prepayment = await this.validateAffectationTypePrepayment()
                if (!error_prepayment.success) {
                    return this.$message.error(error_prepayment.message);
                    this.validarForm = true
                } else {
                    this.validarForm = false
                }
            }


            if (this.is_receivable) {
                this.form.payments = []
            } else {
                let validate = await this.validate_payments()
                if (validate.acum_total > parseFloat(this.form.total) || validate.error_by_item > 0) {
                    this.validarForm = true
                    return this.$message.error('Los montos ingresados superan al monto a pagar o son incorrectos');
                } else {
                    this.validarForm = false
                }
            }

            await this.deleteInitGuides()
            await this.asignPlateNumberToItems()

            let val_detraction = await this.validateDetraction()
            if (!val_detraction.success) {
                this.validarForm = true
                return this.$message.error(val_detraction.message);
            } else {
                this.validarForm = false
            }


            if (this.validarForm == false) {
                let form_efectivo={
                    enter_amount:0,
                    difference:0
                }
                const response_efectivo =  this.$http.post(`/efectivo`,form_efectivo);
                  this.loading_submit = true
                this.$http.post(`/${this.resource}`, this.form).then(response => {
                    if (response.data.success) {
                        this.user_default = this.form.user_id;
                        this.$eventHub.$emit('reloadDataItems', null)
                        this.resetForm();
                        if(this.show_restriction==1){
                         if( this.form_control.stock_control==false){
                            this.form_control.stock_control=true
                            this.update_stock()
                        }

                        }
                        this.documentNewId = response.data.data.id;

                         if(this.configuration.print_direct==1){
                         let formatoPdf="";
                         if(response.data.data.format_printer=="1"){
                              formatoPdf=response.data.data.print_a4
                         }else if(response.data.data.format_printer=="2"){
                             formatoPdf=response.data.data.print_a5
                         }else if(response.data.data.format_printer=="3"){
                             formatoPdf=response.data.data.print_ticket
                         }else if(response.data.data.format_printer=="4"){
                             formatoPdf=response.data.data.print_ticket
                         }

                             this.$confirm('¿Desea Imprimir el Comprobante de Pago ?', 'Comprobante de Pago', {
                             distinguishCancelAndClose: true,
                             confirmButtonText: 'Aceptar',
                             cancelButtonText: 'Cancelar'
                             })
                             .then(() => {
                                    this.print_pdf(response.data.data.printer,formatoPdf)
                                    //location.href = (this.isContingency) ? `/contingencies` : `/${this.resource}`
                             })
                             .catch(action => {
                                if(this.typeUser=="admin"){
                                    location.href = (this.isContingency) ? `/contingencies` : `/${resource}`
                                }else{
                                    location.href = (this.isContingency) ? `/contingencies` : `/restaurant/documents`

                                }
                             });

                         }else{
                            this.showDialogOptions = true;
                        }
                        this.isUpdate()
                    } else {
                        this.$message.error(response.data.message);
                    }
                }).catch(error => {

                    //alert('sdsd')
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else {
                        this.$message.error(error.response.data.message);
                    }
                }).then(() => {
                    this.loading_submit = false;
                });


            }

        },
        validate_payments() {

            //eliminando items de pagos
            for (let index = 0; index < this.form.payments.length; index++) {
                if (parseFloat(this.form.payments[index].payment) === 0)
                    this.form.payments.splice(index, 1)
            }

            let error_by_item = 0
            let acum_total = 0

            this.form.payments.forEach((item) => {
                acum_total += parseFloat(item.payment)
                if (item.payment <= 0 || item.payment == null) error_by_item++;
            })

            return {
                error_by_item: error_by_item,
                acum_total: acum_total
            }

        },

        close() {
            this.$confirm('Desea Cancelar?', 'Warning', {
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cancelar',
                type: 'warning'
            }).then(() => {
                location.href = (this.is_contingency) ? `/contingencies` : `/${this.resource}`
            })


        },
        reloadDataCustomers(customer_id) {
            // this.$http.get(`/${this.resource}/table/customers`).then((response) => {
            //     this.customers = response.data
            //     this.form.customer_id = customer_id
            // })
            this.$http.get(`/${this.resource}/search/customer/${customer_id}`).then((response) => {
                this.customers = response.data.customers
                this.form.customer_id = customer_id
            })
        },
        changeCustomer() {
            this.customer_addresses = [];
            let customer = _.find(this.customers, {'id': this.form.customer_id});
            this.customer_addresses = customer.addresses;
            if (customer.address) {
                this.customer_addresses.unshift({
                    id: null,
                    address: customer.address
                })
            }


            /*if(this.customer_addresses.length > 0) {
                let address = _.find(this.customer_addresses, {'main' : 1});
                this.form.customer_address_id = address.id;
            }*/
        },
        calculateItem(index,quantity,unit_price){
  this.form.items[index].quantity=quantity
             let total_venta=_.round(Math.round(parseFloat(quantity) * parseFloat(unit_price)* 10) / 10,2)
              this.form.items[index].total=total_venta
            if(this.form.items[index].affectation_igv_type_id=="10"){
                this.form.items[index].total_value=(this.form.items[index].total/(1+(this.percentage_igv/100))).toFixed(2)
                this.form.items[index].total_taxes=(((quantity*unit_price)*(this.percentage_igv/100))/(1+(this.percentage_igv/100))).toFixed(2)
                this.form.items[index].total_base_igv=  _.round(this.form.items[index].total/(1+(this.percentage_igv/100)), 2)
                this.form.items[index].unit_value=(unit_price/(1+(this.percentage_igv/100))).toFixed(6)
                this.form.items[index].total_igv=  _.round(((this.form.items[index].total/(1+(this.percentage_igv/100))))*(this.percentage_igv/100), 2)
                 this.form.items[index].total_base_igv=  _.round(this.form.items[index].total/(1+(this.percentage_igv/100)), 2)
            }else{
                this.form.items[index].total_value=Math.round(parseFloat(quantity) * parseFloat(unit_price)* 10) / 10
                this.form.items[index].total_taxes= 0.00
                this.form.items[index].total_base_igv=  _.round(Math.round(parseFloat(quantity) * parseFloat(unit_price)* 10) / 10, 2)
                this.form.items[index].unit_value=unit_price
                this.form.items[index].total_igv= 0
                this.form.items[index].total_base_igv=  _.round(Math.round(parseFloat(quantity) * parseFloat(unit_price)* 10) / 10, 2)
            }

            this.calculateTotal()
        },
        changePaymentCondition() {
            this.form.fee = [];
            this.form.payments = [];
            if (this.form.payment_condition_id === '01') {
                this.clickAddPayment();


            }
            if (this.form.payment_condition_id === '02') {
                this.clickAddFee();
            }
        },
        clickAddFee() {
            this.form.fee.push({
                id: null,
                date: moment(this.form.date_of_issue).add(this.form.fee.length+1, 'days').format('YYYY-MM-DD'),
                currency_type_id: this.form.currency_type_id,
                amount: 0,
            });
            this.calculateFee();
        },
        clickRemoveFee(index) {
            this.form.fee.splice(index, 1);
            this.calculateFee();
        },
        calculateFee() {
            let fee_count = this.form.fee.length;
            let total = this.form.total;
            let accumulated = 0;
            let amount = _.round(total / fee_count, 2);
            _.forEach(this.form.fee, row => {
                accumulated += amount;
                if (total - accumulated < 0) {
                    amount = _.round(total - accumulated + amount, 2);
                }
                row.amount = amount;
            })
        }
    },
    mounted() {
       // this.teclasInit();

        this.loader=true

    },

}
</script>
