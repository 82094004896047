<template>
<div>
           <div class="row">
            <div class="col-12">
              <div class="page-title-container">
                <h1 class="mb-0 pb-0 display-4" id="title">Dashboard</h1>
                <nav class="breadcrumb-container d-inline-block" aria-label="breadcrumb">
                  <ul class="breadcrumb pt-0">
                    <li class="breadcrumb-item"><a href="Dashboards.Default.html">Home</a></li>
                    <li class="breadcrumb-item"><a href="Dashboards.html">Dashboards</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-12">
              <!-- Stats Start -->
              <div class="d-flex">
                <h2 class="small-title">Stats</h2>
              </div>
              <div class="mb-5">
                <div class="row g-2">
                  <div class="col-12 col-sm-3 col-lg-3">
                    <div class="card sh-11 hover-scale-up cursor-pointer">
                      <div class="h-100 row g-0 card-body align-items-center py-3">
                        <div class="col-auto pe-3">
                          <div class="bg-gradient-2 sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center">
                            <i data-cs-icon="navigate-diagonal" class="text-white"></i>
                          </div>
                        </div>
                        <div class="col">
                          <div class="row gx-2 d-flex align-content-center">
                            <div class="col-12 col-xl d-flex">
                              <div class="p mb-0 d-flex align-items-center lh-1-25">Pedidos enviados</div>
                            </div>
                            <div class="col-12 col-xl-auto">
                              <div class="cta-2 text-primary">22</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-3 col-lg-3">
                    <div class="card sh-11 hover-scale-up cursor-pointer">
                      <div class="h-100 row g-0 card-body align-items-center py-3">
                        <div class="col-auto pe-3">
                          <div class="bg-gradient-2 sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center">
                            <i data-cs-icon="check" class="text-white"></i>
                          </div>
                        </div>
                        <div class="col">
                          <div class="row gx-2 d-flex align-content-center">
                            <div class="col-12 col-xl d-flex">
                              <div class="p mb-0 d-flex align-items-center lh-1-25">Pedidos entregados</div>
                            </div>
                            <div class="col-12 col-xl-auto">
                              <div class="cta-2 text-primary">35</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-3 col-lg-3">
                    <div class="card sh-11 hover-scale-up cursor-pointer">
                      <div class="h-100 row g-0 card-body align-items-center py-3">
                        <div class="col-auto pe-3">
                          <div class="bg-gradient-2 sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center">
                            <i data-cs-icon="alarm" class="text-white"></i>
                          </div>
                        </div>
                        <div class="col">
                          <div class="row gx-2 d-flex align-content-center">
                            <div class="col-12 col-xl d-flex">
                              <div class="p mb-0 d-flex align-items-center lh-1-25">Ordenes pendientes</div>
                            </div>
                            <div class="col-12 col-xl-auto">
                              <div class="cta-2 text-primary">14</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-sm-3 col-lg-3">
                    <div class="card sh-11 hover-scale-up cursor-pointer">
                      <div class="h-100 row g-0 card-body align-items-center py-3">
                        <div class="col-auto pe-3">
                          <div class="bg-gradient-2 sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center">
                            <i data-cs-icon="sync-vertical" class="text-white"></i>
                          </div>
                        </div>
                        <div class="col">
                          <div class="row gx-2 d-flex align-content-center">
                            <div class="col-12 col-xl d-flex">
                              <div class="p mb-0 d-flex align-items-center lh-1-25">Pedidos no confirmados</div>
                            </div>
                            <div class="col-12 col-xl-auto">
                              <div class="cta-2 text-primary">3</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Stats End -->
            </div>
            <!-- Sales Start -->
              <div class="col-12 col-lg-6 mb-5">
                <h2 class="small-title mb-3">Ventas</h2>
                <div class="card sh-40">
                    <div class="card-body">
                    <div class="custom-legend-container mb-3 pb-3 d-flex flex-row"></div>
                    <div class="sh-25">
                        <x-graph-line
                          :all-data="general.graph">
                        </x-graph-line>
                    </div>
                    </div>
                </div>
              </div>
              <!-- Sales End -->
            <!-- Products Start -->
            <div class="col-12 col-lg-6">
                <h2 class="small-title mb-3">Stock Producto por agotarse</h2>
                <dashboard-stock></dashboard-stock>
            </div>

            <!-- Products End -->
            <!-- Purchase Start -->
            <div class="col-12 col-lg-6 mb-5">
                 <h2 class="small-title mb-3 mt-3">Compras</h2>  
                <div class="card sh-40">
                    <div class="card-body">
                    <div class="custom-legend-container mb-3 pb-3 d-flex flex-row"></div>
                   
                    <div class="sh-25">
                        <x-graph-line
                            :all-data="purchase.graph">
                        </x-graph-line>
                    </div>
                    </div>
                </div>
            </div>
            
                <div class="col-xl-3 col-md-3">
            <h2 class="small-title mb-3 mt-3">Utilidades/Ganancias</h2>
            <section class="card card-featured-left card-featured-secondary">
              <div class="card-body" v-if="utilities">
                <div class="widget-summary">
                  <div class="widget-summary-col">
                    <div class="row no-gutters">
                   
                      <div class="col-lg-4">
                        <div class="summary">
                          <h4 class="small-title text-info">
                            Ingreso
                          </h4>
                          <div class="info">
                            <strong class="amount text-info">S/ {{ utilities.totals.total_income }}</strong>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="summary">
                          <h4 class="small-title text-danger">
                            Egreso
                          </h4>
                          <div class="info">
                            <strong class="amount text-danger">S/ {{ utilities.totals.total_egress }}</strong>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="summary">
                          <h4 class="small-title text-success">
                            Utilidad
                          </h4>
                          <div class="info">
                            <strong class="amount">S/ {{ utilities.totals.utility }}</strong>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-lg-12 ">
                        <div class="summary">
                          <h4 class="title">
                             <el-checkbox  v-model="form.enabled_expense" @change="loadDataUtilities">Considerar gastos</el-checkbox>  
                             <el-checkbox  v-model="filter_item" @change="changeFilterItem">Filtrar por producto</el-checkbox>
                          </h4>
                        </div>
                      </div> -->
                     
                      <!-- <div class="col-lg-12 " v-if="filter_item">
                        <div class="summary">
                          <h4 class="title">
                            <div class="form-group">
                                <el-select v-model="form.item_id" filterable remote  popper-class="el-select-customers"  clearable
                                    placeholder="Buscar producto"
                                    :remote-method="searchRemoteItems"
                                    :loading="loading_search"
                                    @change="loadDataUtilities">
                                    <el-option v-for="option in items" :key="option.id" :value="option.id" :label="option.description"></el-option>
                                </el-select>
                            </div>
                          </h4>
                        </div>
                      </div> -->
                    </div>
                    <div class="row m-t-20">
                      <div class="col-md-12">
                       <graphApex v-if="seriesUtilidad.length>0"
                            :allData.sync="seriesUtilidad"
                            :labels.sync="labels_utilidad"
                            :colors.sync="utilities.graph.datasets[0].backgroundColor"
                            TitleText="Utilidades/Ganancias"
                            type_chart='donut'>
                        </graphApex>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div> 
              <!-- Purchase End -->
              <div class="col-xl-3 col-md-3">
             <h2 class="small-title mb-3 mt-3">Balance Ventas - Compras - Gastos</h2>
            <section class="card card-featured-left card-featured-secondary">
              <div class="card-body">
                <div class="widget-summary">

                  <div class="widget-summary-col">
                    <div class="row no-gutters">
                      <div class="col-lg-6">
                        <div class="summary">
                          <h4 class="small-title text-info">
                            Totales
                          </h4>
                          <div class="info">
                            <strong class="amount text-info">S/ {{ balance.totals.all_totals }}</strong>
                          </div>
                            <el-popover placement="right" width="100%" trigger="hover">
                              <p><span class="custom-badge">T. Ventas - T. Compras/Gastos</span></p>
                              <p>Total comprobantes:<span class="custom-badge pull-right">S/ {{ balance.totals.total_document }}</span></p>
                              <p>Total Nota de venta:<span class="custom-badge pull-right">S/ {{ balance.totals.total_sale_note }}</span></p>
                              <p>Total compras:<span class="custom-badge pull-right">- S/ {{ balance.totals.total_purchase }}</span></p>
                              <p>Total gastos:<span class="custom-badge pull-right">- S/ {{ balance.totals.total_expense }}</span></p>
                              <el-button icon="el-icon-view" type="primary" size="mini" slot="reference" circle></el-button>
                            </el-popover>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="summary">
                          <h4 class="small-title text-danger">
                            Total Pagos
                          </h4>
                          <div class="info">
                            <strong class="amount text-danger">S/ {{ balance.totals.all_totals_payment }}</strong>
                          </div>
                           <el-popover placement="right" width="100%" trigger="hover">
                              <p><span class="custom-badge">T. Pagos Ventas - T. Pagos Compras/Gastos</span></p>
                              <p>Total pagos comprobantes:<span class="custom-badge pull-right">S/ {{ balance.totals.total_payment_document }}</span></p>
                              <p>Total pagos Nota de venta:<span class="custom-badge pull-right">S/ {{ balance.totals.total_payment_sale_note }}</span></p>
                              <p>Total pagos compras:<span class="custom-badge pull-right">- S/ {{ balance.totals.total_payment_purchase }}</span></p>
                              <p>Total pagos gastos:<span class="custom-badge pull-right">- S/ {{ balance.totals.total_payment_expense }}</span></p>
                              <el-button icon="el-icon-view" type="danger" size="mini" slot="reference" circle></el-button>
                            </el-popover>
                        </div>
                      </div>
                      <!-- <div class="col-lg-4">
                        <div class="summary">
                          <h4 class="title">
                            Total
                            <br />&nbsp;
                          </h4>
                          <div class="info">
                            <strong class="amount">S/ {{ balance.totals.total }}</strong>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <div class="row m-t-20">
                      <div class="col-md-12">
                        <!-- <x-graph type="doughnut" :all-data="balance.graph"></x-graph> -->
                         <div id="chart" >
                        <graphApex
                            v-if="seriesBalance.length>0"
                            :allData.sync="seriesBalance"
                            :labels.sync="labels_balance"
                            :colors.sync="colorsSalesNote"
                            TitleText="Balance"
                            type_chart='donut'>
                        </graphApex>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

            <div class="col-xl-6 col-md-6">
            <h2 class="small-title mb-3 mt-3">Ventas por producto</h2>
            <section class="card">
              <div class="card-body">
                <div class="mt-3">
                  <el-checkbox  v-model="form.enabled_move_item" @change="loadDataAditional">Ordenar por movimientos</el-checkbox><br>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th class="text-end">
                          Mov.
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="Movimientos (Cantidad de veces vendido)"
                              placement="top-start"
                            >
                              <i class="fa fa-info-circle"></i>
                            </el-tooltip>
                        </th>
                        <th class="text-end">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(row, index) in items_by_sales">
                        <tr :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>{{ row.internal_id }}</td>
                          <td>{{ row.description }}</td>
                          <td class="text-end">{{ row.move_quantity }}</td>
                          <td class="text-end">{{ row.total }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        
          <div class="col-xl-6 col-md-6">
              <h2 class="small-title mb-3 mt-3">Top clientes</h2>
            <section class="card">
              <div class="card-body">
                
                <div class="mt-3">
                  <el-checkbox  v-model="form.enabled_transaction_customer" @change="loadDataAditional">Ordenar por transacciones</el-checkbox><br>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Cliente</th>
                        <th class="text-end">
                          Trans.
                            <el-tooltip
                              class="item"
                              effect="dark"
                              content="Transacciones (Cantidad de ventas realizadas)"
                              placement="top-start"
                            >
                              <i class="fa fa-info-circle"></i>
                            </el-tooltip>
                        </th>
                        <th class="text-end">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(row, index) in top_customers">
                        <tr :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>
                            {{ row.name }}
                            <br />
                            <small v-text="row.number"></small>
                          </td>
                          <td class="text-end">{{ row.transaction_quantity }}</td>
                          <td class="text-end">{{ row.total }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>


    </div>

</div>
</template>
<style>
.widget-summary .summary {
  min-height: inherit;
}
main{
    overflow-x: hidden !important;
}
.custom-badge {
  font-size: 15px;
  font-weight: bold;
}
</style>
<script>
// import DocumentPayments from "../../../../../../resources/js/views/tenant/documents/partials/payments.vue";
// import SaleNotePayments from "../../../../../../resources/js/views/tenant/sale_notes/partials/payments.vue";
import DashboardStock from "./partials/dashboard_stock.vue";
import queryString from "query-string";
import VueApexCharts from 'vue-apexcharts';

import graphApex from "../components/GraphApexcharts.vue"
 import LoaderGraph from "../components/loaders/l-graph.vue";
export default {
  props: ["typeUser", "soapCompany"],
  components: { DashboardStock,LoaderGraph,graphApex,apexchart: VueApexCharts },
  data() {
    return {
 
      loading_search:false,
      records_base: [],
      selected_customer: null,
      customers: [],
      resource: "dashboard",
      labels_balance:['Totales','Total Pagos'],
      colorsSalesNote:['#2499e3', '#59bfff'],
      establishments: [],
      seriesUtilidad:[],
      seriesDocuments:[],
      seriesBalance:[],
      labels_utilidad:[],
      labels_douments:[],
      balance: {
        totals: {},
        graph: {}
      },
      document: {
        totals: {},
        graph: {}
      },
      sale_note: {
        totals: {},
        graph: {}
      },
      general: {
        totals: {},
        graph: {}
      },
      purchase: {
        totals: {},
        graph: {}
      },
      utilities: {
        totals: {},
        graph: {}
      },
      disc: [],
      form: {},
      pickerOptionsDates: {
        disabledDate: time => {
          time = moment(time).format("YYYY-MM-DD");
          return this.form.date_start > time;
        }
      },
      pickerOptionsMonths: {
        disabledDate: time => {
          time = moment(time).format("YYYY-MM");
          return this.form.month_start > time;
        }
      },
      records: [],
      items_by_sales: [],
      top_customers: [],
      recordId: null,
      showDialogDocumentPayments: false,
      showDialogSaleNotePayments: false,
      filter_item:false,
      all_items: [],
      items:[],
       chartOptions: {
        chart: {
            type: this.type_chart
         },
         title: {
            text: this.TitleText,
            align: 'center',
            style: {
              fontSize:  '14px',
              fontWeight:  'bold',
              fontFamily: 'Nunito, sans-serif',
              color:  '#000'
            },
        },
        colors: this.colors,
        dataLabels: {
          enabled: false
        },
        legend: {
           
            position: 'bottom',
             fontSize: '14px',
                   
          labels:    {
          color: '#000',
          useSeriesColors: false
   },
       
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            donut: {
              size: '85%',
              background: 'transparent',
              labels: { 
                show: true, 
                name: { 
                  show: true ,
                 },
                value: { 
                  show: true,
                  fontSize: '26px',
                  fontFamily: 'Nunito, sans-serif',
                  color: '#000',
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
              show: true,
              label: 'Total',
              color: '#000',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce( function(a, b) {
                  return ((a + b))
                }, 0)
              }
            }
              }
            }
          }
        },
        stroke: {
          show: false,
         },
        labels:this.labels,
        responsive: [{
            breakpoint: 1599,
            options: {
                chart: {
                    width: '350px',
                    height: '400px'
                },
                legend: {
                    position: 'top'
                }
            },
    
            breakpoint: 1439,
            options: {
                chart: {
                    width: '250px',
                    height: '390px'
                },
                legend: {
                    position: 'top'
                },
                plotOptions: {
                  pie: {
                    donut: {
                      size: '65%',
                    }
                  }
                }
            },
        }]
        }, 
    };
  },
  async created() {
    this.initLoaders();
    this.initForm();
    await this.$http.get(`/${this.resource}/filter`).then(response => {
      this.establishments = response.data.establishments;
      this.form.establishment_id =
        this.establishments.length > 0 ? this.establishments[0].id : null;
    });
    await this.loadAll();
    await this.filterItems()

    // this.$eventHub.$on("reloadDataUnpaid", () => {
    //   this.loadAll();
    // });
  },

  methods: {
    changeFilterItem(){
      this.form.item_id = null
      this.loadDataUtilities()
    },
    searchRemoteItems(input) {

        if (input.length > 1) {

            this.loading_search = true
            let parameters = `input=${input}`


            this.$http.get(`/reports/data-table/items/?${parameters}`)
                    .then(response => {
                        this.items = response.data.items
                        this.loading_search = false

                        if(this.items.length == 0){
                            this.filterItems()
                        }
                    })
        } else {
            this.filterItems()
        }

    },
    filterItems() {
        this.items = this.all_items
    },
    calculateTotalCurrency(currency_type_id, exchange_rate_sale,  total )
    {
        if(currency_type_id == 'USD')
        {
            return parseFloat(total) * exchange_rate_sale;
        }
        else{
            return parseFloat(total);
        }
    },
    clickDownloadDispatch(download) {
      window.open(download, "_blank");
    },
    clickDownload(type) {
      let query = queryString.stringify({
        ...this.form
      });
      window.open(`/reports/no_paid/${type}/?${query}`, "_blank");
    },
    initForm() {
      this.form = {
        item_id: null,
        establishment_id: null,
        enabled_expense: null,
        enabled_move_item:false,
        enabled_transaction_customer:false,
        period: "all",
        date_start: moment().format("YYYY-MM-DD"),
        date_end: moment().format("YYYY-MM-DD"),
        month_start: moment().format("YYYY-MM"),
        month_end: moment().format("YYYY-MM"),
        customer_id: null
      };
    },
    initLoaders() {
        this.loaders = {
            document: true,
            sale_note: true,
            general: true,
            balance: true,
            utility: true,
            purchase: true,
            items_by_sales: true,
            top_customers: true,
        };
        },
        showLoadersLoadData() {
        this.loaders.document = true;
        this.loaders.sale_note = true;
        this.loaders.general = true;
        this.loaders.balance = true;
        },
        hideLoadersLoadData() {
        this.loaders.document = false;
        this.loaders.sale_note = false;
        this.loaders.general = false;
        this.loaders.balance = false;
        },
    changeDisabledDates() {
      if (this.form.date_end < this.form.date_start) {
        this.form.date_end = this.form.date_start;
      }
      this.loadAll();
    },
    changeDisabledMonths() {
      if (this.form.month_end < this.form.month_start) {
        this.form.month_end = this.form.month_start;
      }
      this.loadAll();
    },
    changePeriod() {
      if (this.form.period === "month") {
        this.form.month_start = moment().format("YYYY-MM");
        this.form.month_end = moment().format("YYYY-MM");
      }
      if (this.form.period === "between_months") {
        this.form.month_start = moment()
          .startOf("year")
          .format("YYYY-MM"); //'2019-01';
        this.form.month_end = moment()
          .endOf("year")
          .format("YYYY-MM");
      }
      if (this.form.period === "date") {
        this.form.date_start = moment().format("YYYY-MM-DD");
        this.form.date_end = moment().format("YYYY-MM-DD");
      }
      if (this.form.period === "between_dates") {
        this.form.date_start = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        this.form.date_end = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      }
      this.loadAll();
    },
    async loadAll() {
      await this.loadData();
      await this.loadDataAditional();
      //await this.loadDataUtilities();
      //this.loadCustomer();
    },
    async loadData() {
      await this.$http.post(`/${this.resource}/data`, this.form).then(response => {
        this.document = response.data.data.document;
        this.balance = response.data.data.balance;
        this.sale_note = response.data.data.sale_note;
        this.general = response.data.data.general;
        this.customers = response.data.data.customers;
        this.items = response.data.data.items;
        this.seriesDocuments=response.data.data.document.graph.datasets[0].data
        this.labels_douments=response.data.data.document.graph.labels
        for (let index = 0; index < response.data.data.balance.graph.datasets[0].data.length; index++) {
         this.seriesBalance.push(
            parseFloat(response.data.data.balance.graph.datasets[0].data[index])
         )            
        }

      });
        await  this.$http.post(`/${this.resource}/utilities`, this.form)
            .then((response) => {
            this.utilities = response.data.data.utilities;
        for (let index = 0; index < response.data.data.utilities.graph.datasets[0].data.length; index++) {
            this.seriesUtilidad.push(parseFloat(response.data.data.utilities.graph.datasets[0].data[index]))
         }    

            this.labels_utilidad=this.utilities.graph.labels
            this.loaders.utility = false;
            });
    //   this.$http.get(`/command/df`).then(response => {
    //     if (response.data[0] != 'error'){
    //       this.disc.used = Number(response.data[0].replace(/[^0-9\.]+/g,""));
    //       this.disc.avail = Number(response.data[1].match(/\d/g).join(""));
    //       this.disc.pcent = Number(response.data[2].match(/\d/g).join(""));
    //     } else {
    //       this.disc.error = true;
    //     }
    //   });
    },
    loadDataAditional() {
      this.$http
        .post(`/${this.resource}/data_aditional`, this.form)
        .then(response => {
          this.purchase = response.data.data.purchase;
          this.items_by_sales = response.data.data.items_by_sales;
          this.top_customers = response.data.data.top_customers;
        });
    },
    loadDataUtilities() {
        this.loaders.utility = true;
        this.$http.post(`/${this.resource}/utilities`, this.form)
            .then((response) => {
            this.utilities = response.data.data.utilities;
        for (let index = 0; index < response.data.data.utilities.graph.datasets[0].data.length; index++) {
            this.seriesUtilidad.push(parseFloat(response.data.data.utilities.graph.datasets[0].data[index]))
            console.log("parseFloat(response.data.data.utilities.graph.datasets[0].data[index])",parseFloat(response.data.data.utilities.graph.datasets[0].data[index]))            
        }    

            this.labels_utilidad=this.utilities.graph.labels
            this.loaders.utility = false;
            });
        },
  }
};
</script>
