<template>
    <el-dialog :title="titleDialog" :visible="showDialog" @close="close" @open="create">
        <form autocomplete="off" @submit.prevent="submit">
            <div class="form-body">
                <div class="row">
                    <div class="col-md-8">
                        <div class="form-group" :class="{'has-danger': errors.description}">
                            <label class="control-label">Descripción</label>
                            <el-input v-model="form.description"> </el-input>
                            <small class="form-control-feedback" v-if="errors.description" v-text="errors.description[0]"></small>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group" :class="{'has-danger': errors.code}">
                            <label class="control-label">Código Domicilio Fiscal</label>
                            <el-input v-model="form.code" :maxlength="4"> </el-input>
                            <small class="form-control-feedback" v-if="errors.code" v-text="errors.code[0]"></small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group" :class="{'has-danger': errors.country_id}">
                            <label class="control-label">País</label>
                            <el-select v-model="form.country_id" filterable>
                                <el-option v-for="option in countries" :key="option.id" :value="option.id" :label="option.description"></el-option>
                            </el-select>
                            <small class="form-control-feedback" v-if="errors.country_id" v-text="errors.country_id[0]"></small>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group" :class="{'has-danger': errors.department_id}">
                            <label class="control-label">Departamento</label>
                            <el-select v-model="form.department_id" filterable @change="filterProvince">
                                <el-option v-for="option in all_departments" :key="option.id" :value="option.id" :label="option.description"></el-option>
                            </el-select>
                            <small class="form-control-feedback" v-if="errors.department_id" v-text="errors.department_id[0]"></small>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group" :class="{'has-danger': errors.province_id}">
                            <label class="control-label">Provincia</label>
                            <el-select v-model="form.province_id" filterable @change="filterDistrict">
                                <el-option v-for="option in provinces" :key="option.id" :value="option.id" :label="option.description"></el-option>
                            </el-select>
                            <small class="form-control-feedback" v-if="errors.province_id" v-text="errors.province_id[0]"></small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group" :class="{'has-danger': errors.province_id}">
                            <label class="control-label">Distrito</label>
                            <el-select v-model="form.district_id" filterable>
                                <el-option v-for="option in districts" :key="option.id" :value="option.id" :label="option.description"></el-option>
                            </el-select>
                            <small class="form-control-feedback" v-if="errors.district_id" v-text="errors.district_id[0]"></small>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group" :class="{'has-danger': errors.address}">
                            <label class="control-label">Dirección Fiscal</label>
                            <el-input v-model="form.address"> </el-input>
                            <small class="form-control-feedback" v-if="errors.address" v-text="errors.address[0]"></small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group" :class="{'has-danger': errors.telephone}">
                            <label class="control-label">Teléfono</label>
                            <el-input v-model="form.telephone"> </el-input>
                            <small class="form-control-feedback" v-if="errors.telephone" v-text="errors.telephone[0]"></small>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group" :class="{'has-danger': errors.trade_address}">
                            <label class="control-label">Dirección Comercial</label>
                            <el-input v-model="form.trade_address"> </el-input>
                            <small class="form-control-feedback" v-if="errors.trade_address" v-text="errors.trade_address[0]"></small>
                        </div>
                    </div>
                </div>
                 <div class="row">
                    <div class="col-md-4">
                        <div class="form-group" :class="{'has-danger': errors.email}">
                            <label class="control-label">Correo de contacto</label>
                            <el-input v-model="form.email"> </el-input>
                            <small class="form-control-feedback" v-if="errors.email" v-text="errors.email[0]"></small>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="form-group" :class="{'has-danger': errors.web_address}">
                            <label class="control-label">Dirección web</label>
                            <el-input v-model="form.web_address"> </el-input>
                            <small class="form-control-feedback" v-if="errors.web_address" v-text="errors.web_address[0]"></small>
                        </div>
                    </div>
                      <div class="col-md-4">
                        <div class="form-group" :class="{'has-danger': errors.direct_printing}">
                            <h2><el-switch v-model="form.direct_printing" active-text="Impresion Directa"></el-switch></h2>
                            <small class="form-control-feedback" v-if="errors.direct_printing" v-text="errors.direct_printing[0]"></small>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group" :class="{'has-danger': errors.printer}">
                            <label class="control-label">Nombre de Impresora</label>
                               <el-input v-model="form.printer"> </el-input>
                            <small class="form-control-feedback" v-if="errors.printer" v-text="errors.printer[0]"></small>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group" :class="{'has-danger': errors.copies}">
                            <label class="control-label">Nº Copiassssss </label>
                               <el-input v-model="form.copies"> </el-input>
                            <small class="form-control-feedback" v-if="errors.copies" v-text="errors.copies[0]"></small>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group" :class="{'has-danger': errors.format_printer}">
                            <label class="control-label">Tamaño hoja </label>
                                 <el-select v-model="form.format_printer" slot="prepend" placeholder="Seleccione el Tipo de Papel">
                                    <el-option label="Tamaño A4" value="1"></el-option>
                                    <el-option label="Tamaño A5" value="2"></el-option>
                                    <el-option label="Tamaño Ticket 80MM" value="3"></el-option>
                                    <el-option label="Tamaño Ticket 50MM" value="4"></el-option>
                                </el-select>
                            <small class="form-control-feedback" v-if="errors.format_printer" v-text="errors.format_printer[0]"></small>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group" :class="{'has-danger': errors.customer_id}">
                            <label class="control-label">Cliente por defecto</label>
                                <el-select v-model="form.customer_id" slot="prepend" placeholder="Seleccione el Cliente por defecto">
                                   <el-option v-for="option in customers" :key="option.id" :value="option.id" :label="option.name"></el-option>
                                 </el-select>
                            <small class="form-control-feedback" v-if="errors.customer_id" v-text="errors.customer_id[0]"></small>
                        </div>
                    </div>
                     <div class="col-md-6">
                        <div class="form-group" :class="{'has-danger': errors.document_default}">
                            <label class="control-label">Comprobante por defecto</label>
                                <el-select v-model="form.document_default" slot="prepend" placeholder="Seleccione el Comprobante">
                                    <el-option label="FACTURA ELECTRÓNICA" value="01"></el-option>
                                    <el-option label="BOLETA DE VENTA" value="03"></el-option>
                                    <el-option label="NOTA DE VENTA" value="80"></el-option>
                                 </el-select>
                            <small class="form-control-feedback" v-if="errors.document_default" v-text="errors.document_default[0]"></small>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group" :class="{'has-danger': errors.has_igv_31556}">
                               <el-checkbox v-model="form.has_igv_31556">
                                Sujeto al IGV - Ley 31556
                            </el-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-actions text-end pt-2 pb-2">
                <el-button @click.prevent="close()">Cancelar</el-button>
                <el-button type="primary" native-type="submit" :loading="loading_submit">Guardar</el-button>
            </div>
        </form>
    </el-dialog>

</template>

<script>

    export default {
        props: ['showDialog', 'recordId'],
        data() {
            return {
                loading_submit: false,
                titleDialog: null,
                resource: 'establishments',
                errors: {},
                form: {},
                countries: [],
                all_departments: [],
                all_provinces: [],
                all_districts: [],
                customers: [],
                provinces: [],
                districts: [],
            }
        },
        async created() {
            await this.initForm()
            await this.$http.get(`/${this.resource}/tables`)
                .then(response => {
                    this.countries = response.data.countries
                    this.all_departments = response.data.departments
                    this.all_provinces = response.data.provinces
                    this.customers=response.data.customers
                    this.all_districts = response.data.districts
                })
        },
          mounted() {
        //     console.log('res1');
        //     qz.security.setCertificatePromise((resolve, reject) => {
        //         this.$http.get('/api/qz/crt/override', {
        //             responseType: 'text'
        //         }).then(response => {
        //             console.log('res2');
        //             resolve(response.data);
        //         }).catch(error => {
        //             reject(error.data);
        //         });
        //     });

        //    console.log('res2');
        //     qz.security.setSignaturePromise((toSign) => {
        //         return (resolve, reject) => {
        //             this.$http.post('/api/qz/signing', {request: toSign})
        //                 .then(response => {
        //                    console.log('res2');
        //                     resolve(response.data);
        //                 }).catch(error => {
        //                 reject(error.data);
        //             });
        //         };
        //     });
        },
        methods: {

            initForm() {
                this.errors = {}
                this.form = {
                    id: null,
                    description: null,
                    country_id: 'PE',
                    department_id: null,
                    province_id: null,
                    district_id: null,
                    address: null,
                    telephone: null,
                    email: null,
                    code: null,
                    trade_address: null,
                    web_address: null,
                    aditional_information: null,
                    format_printer:null,
                    printer:null,
                    direct_printing:null,
                    document_default:null,
                    printer_serve:null,
                    customer_id:null,
                    copies:0,
                    has_igv_31556:false
                }
            },
            async create() {
                this.titleDialog = (this.recordId)? 'Editar Establecimiento':'Nuevo Establecimiento'
                if (this.recordId) {
                    await this.$http.get(`/${this.resource}/record/${this.recordId}`)
                        .then(response => {
                            if (response.data !== '') {
                                this.form = response.data.data
                                if(this.form.direct_printing=="1"){
                                    this.form.direct_printing=true
                                }else{
                                    this.form.direct_printing=false
                                }
                                this.filterProvinces()
                                this.filterDistricts()
                            }
                        })
                }
            },
            submit() {
                this.loading_submit = true
                this.$http.post(`/${this.resource}`, this.form)
                    .then(response => {
                        if (response.data.success) {
                            this.$message.success(response.data.message)
                            this.$eventHub.$emit('reloadData')
                            this.close()
                        } else {
                            this.$message.error(response.data.message)
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.errors = error.response.data
                        } else {
                            console.log(error)
                        }
                    })
                    .then(() => {
                        this.loading_submit = false
                    })
            },
            filterProvince() {
                this.form.province_id = null
                this.form.district_id = null
                this.filterProvinces()
            },
            filterProvinces() {
                this.provinces = this.all_provinces.filter(f => {
                    return f.department_id === this.form.department_id
                })
            },
            filterDistrict() {
                this.form.district_id = null
                this.filterDistricts()
            },
            filterDistricts() {
                this.districts = this.all_districts.filter(f => {
                    return f.province_id === this.form.province_id
                })
            },
            close() {
                this.$emit('update:showDialog', false)
                this.initForm()
            },
        }
    }
</script>
