<template>
    <div>

        <div class="card">
            <div class="card-header bg-primary">
                <h6 class="my-0 text-white">Configuraciones</h6>
            </div>
            <div class="card-body pt-0 pb-5">
                <form autocomplete="off">
                    <div class="form-body">
                        <el-tabs type="border-card">
                            <el-tab-pane label="Servicios">
                                <div class="row">
                                <div class="col-md-4">
                                    <label class="control-label">Envío de comprobantes automático</label>
                                    <div class="form-group" :class="{'has-danger': errors.send_auto}">
                                        <el-switch v-model="form.send_auto" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.send_auto" v-text="errors.send_auto[0]"></small>
                                    </div>
                                </div>
                                <div class="col-md-4" v-if="typeUser != 'integrator'">
                                    <label class="control-label">Crontab <small>Tareas Programadas</small></label>
                                    <div class="form-group" :class="{'has-danger': errors.cron}">
                                        <el-switch v-model="form.cron" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.cron" v-text="errors.cron[0]"></small>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6 mt-4" v-if="typeUser != 'integrator'">
                                    <label class="control-label">Envío de comprobantes a servidor alterno de SUNAT</label>
                                    <div class="form-group" :class="{'has-danger': errors.sunat_alternate_server}">
                                        <el-switch v-model="form.sunat_alternate_server" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                        <small class="text-danger" v-if="errors.sunat_alternate_server" v-text="errors.sunat_alternate_server[0]"></small>
                                    </div>
                                </div> -->
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="Contable">
                                <div class="row">
                                    <div class="col-md-4" v-if="typeUser != 'integrator'">
                                        <label class="control-label">Cantidad decimales POS</label>
                                        <div class="form-group" :class="{'has-danger': errors.decimal_quantity}">
                                            <el-input-number v-model="form.decimal_quantity" @change="submit" :min="2" :max="10"></el-input-number>
                                            <small class="text-danger" v-if="errors.decimal_quantity" v-text="errors.decimal_quantity[0]"></small>
                                        </div>
                                    </div>

                                    <div class="col-md-4" v-if="typeUser != 'integrator'">
                                        <label class="control-label">Impuesto bolsa plástica</label>
                                        <div class="form-group" :class="{'has-danger': errors.amount_plastic_bag_taxes}">
                                            <el-input-number v-model="form.amount_plastic_bag_taxes" @change="changeAmountPlasticBagTaxes" :precision="2" :step="0.1" :max="0.5" :min="0.1"></el-input-number>
                                            <small class="text-danger" v-if="errors.amount_plastic_bag_taxes" v-text="errors.amount_plastic_bag_taxes[0]"></small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <div class="form-group" :class="{'has-danger': errors.affectation_igv_type_id}">
                                            <label class="control-label">Tipo de afectación
                                                <el-tooltip class="item" effect="dark" content="Tipo de afectación predeterminada al registrar nuevo producto" placement="top-start">
                                                    <i class="fa fa-info-circle"></i>
                                                </el-tooltip>
                                            </label>
                                            <el-select v-model="form.affectation_igv_type_id" @change="submit" filterable>
                                                <el-option v-for="option in affectation_igv_types" :key="option.id" :value="option.id" :label="option.description"></el-option>
                                            </el-select>
                                            <small class="text-danger" v-if="errors.affectation_igv_type_id" v-text="errors.affectation_igv_type_id[0]"></small>
                                        </div>
                                    </div>

                                </div>
                            </el-tab-pane>
                            <el-tab-pane label="Visual">
                                <div class="row">

                                        <div class="col-md-6 mt-2">
                                            <label class="control-label">Uso de Comanda Fisica (Modulo caja)</label>
                                            <div class="form-group" :class="{'has-danger': errors.commands_fisico}">
                                                <el-switch v-model="form.commands_fisico" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                                <small class="text-danger" v-if="errors.commands_fisico" v-text="errors.commands_fisico[0]"></small>
                                            </div>
                                        </div>
                                         <div class="col-md-6 mt-2">
                                            <label class="control-label">Recrear Documento</label>
                                            <div class="form-group" :class="{'has-danger': errors.recreat_document}">
                                                <el-switch v-model="form.recreat_document" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                                <small class="text-danger" v-if="errors.recreat_document" v-text="errors.recreat_document[0]"></small>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <label class="control-label">Eliminar Documento</label>
                                            <div class="form-group" :class="{'has-danger': errors.delete_document}">
                                                <el-switch v-model="form.delete_document" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                                <small class="text-danger" v-if="errors.delete_document" v-text="errors.delete_document[0]"></small>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-2">
                                            <label class="control-label">Editar Precio Venta en Caja</label>
                                            <div class="form-group" :class="{'has-danger': errors.edit_price_sales}">
                                                <el-switch v-model="form.edit_price_sales" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                                <small class="text-danger" v-if="errors.edit_price_sales" v-text="errors.edit_price_sales[0]"></small>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mt-4">
                                            <label class="control-label">Editar nombre de productos</label>
                                            <div class="form-group" :class="{'has-danger': errors.edit_name_product}">
                                                <el-switch v-model="form.edit_name_product" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                                <small class="text-danger" v-if="errors.edit_name_product" v-text="errors.edit_name_product[0]"></small>
                                            </div>
                                        </div>

                                    <div class="col-md-6 mt-4">
                                        <label class="control-label">Restringir fecha de comprobante</label>
                                        <div class="form-group" :class="{'has-danger': errors.restrict_receipt_date}">
                                            <el-switch v-model="form.restrict_receipt_date" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                            <small class="text-danger" v-if="errors.restrict_receipt_date" v-text="errors.restrict_receipt_date[0]"></small>
                                        </div>
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <label class="control-label">Impuesto incluido en registro de productos</label>
                                        <div class="form-group" :class="{'has-danger': errors.include_igv}">
                                            <el-switch v-model="form.include_igv" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                            <small class="text-danger" v-if="errors.include_igv" v-text="errors.include_igv[0]"></small>
                                        </div>
                                    </div>

                                    <div class="col-md-6 mt-4">
                                        <div class="form-group">
                                            <label class="control-label w-100">Impresión Directa</label>
                                            <el-switch v-model="form.print_direct" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                    </div>
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <div class="form-group">
                                            <label class="control-label w-100">Multiples Caja (Usuario Cajero)</label>
                                            <el-switch v-model="form.multiple_boxes" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                    </div>
                                    </div>
                                      <div class="col-md-6 mt-4">
                                        <div class="form-group">
                                            <label class="control-label w-100">Venta Rapida (Modulo Caja)</label>
                                            <el-switch v-model="form.sales_quick" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                    </div>
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <div class="form-group">
                                            <label class="control-label w-100">Imprimir Comanda(Modulo de Caja)</label>
                                            <el-switch v-model="form.print_commands" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                    </div>
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <div class="form-group">
                                            <label class="control-label w-100">Vender sin stock</label>
                                            <el-switch v-model="form.sales_stock" active-text="Si" inactive-text="No" @change="submit();stock_control()"></el-switch>
                                    </div>
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <div class="form-group">
                                            <label class="control-label w-100">Entorno Restaurant</label>
                                            <el-switch v-model="form.restaurant" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                    </div>
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <div class="form-group">
                                            <label class="control-label w-100">Imprimir en Zona Preparación</label>
                                            <el-switch v-model="form.print_kitchen" active-text="Si" inactive-text="No" @change="submit"></el-switch>
                                    </div>
                                    </div>
                                     <div class="col-md-6 mt-4">
                                        <div class="form-group" :class="{'has-danger': errors.affectation_igv_type_id}">
                                            <label class="control-label">(Modulo de Mozo) Busqueda de Producto por
                                             </label>
                                            <el-select v-model="form.search_type" @change="submit" filterable>
                                                  <el-option label="Buscar por Descripcion" value="0"></el-option>
                                                  <el-option label="Buscar por Codigo interno" value="1"></el-option>
                                             </el-select>
                                            <small class="text-danger" v-if="errors.affectation_igv_type_id" v-text="errors.affectation_igv_type_id[0]"></small>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>

                        </el-tabs>






                    </div>
                </form>
            </div>

        </div>
    </div>
</template>

<script>

  //  import TermsCondition from '@views/quotations/partials/terms_condition.vue'

    export default {
        props:['typeUser'],
      //  components: {TermsCondition},

        data() {
            return {
                showDialogTermsCondition:false,
                loading_submit: false,
                resource: 'configurations',
                errors: {},
                form: {},
                affectation_igv_types: [],
                placeholder:'',

            }
        },
        async created() {
            await this.loadTables()
            await this.initForm();

            await this.$http.get(`/${this.resource}/record`) .then(response => {
                if (response.data !== ''){
                this.form = response.data.data;
                console.log(this.form);
                }
                // console.log(this.placeholder)
            });
        },
        methods: {
            async loadTables(){

                await this.$http.get(`/${this.resource}/tables`) .then(response => {
                        this.affectation_igv_types = response.data.affectation_igv_types
                    })

            },
            initForm() {
                this.errors = {};
                this.form = {
                    send_auto: true,
                    stock: true,
                    cron: true,
                    id: null,
                    rates:0,
                    sunat_alternate_server: false,
                    subtotal_account:null,
                    decimal_quantity: null,
                    amount_plastic_bag_taxes: 0.1,
                    colums_grid_item: 4,
                    affectation_igv_type_id:'10',
                    terms_condition:null,
                    compact_sidebar:null,
                    delete_document:null,
                    recreat_document:null,
                    show_restriction:null,
                    print_direct:null,
                    socket_channel:null,
                    refresh_price_sales:null,
                    search_type:"0",
                    multiple_boxes:null,
                    sales_stock:false,
                    print_commands:false,
                    commands_fisico:false,
                    sales_quick:false,
                    direct_printing:false,
                    restaurant:false,
                    print_kitchen:false
                };
            },
            async submit() {
                this.loading_submit = true;

                await this.$http.post(`/${this.resource}`, this.form).then(response => {
                    if (response.data.success) {
                        this.$message.success(response.data.message);
                    }
                    else {
                        this.$message.error(response.data.message);
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                    else {
                        console.log(error);
                    }
                }).then(() => {
                    this.loading_submit = false;
                });

            },
            async stock_control(){
                // if(this.form.sales_stock==true){
                  let form = {
                    id: 1,
                    stock_control: !this.form.sales_stock
                  }
                  console.log(form)
                  const response = await this.$http.post(`/inventories/configuration`,form)
                    if (response.data.success) {
                            this.$message.success(response.data.message);
                    }
              //  }
            },
            changeAmountPlasticBagTaxes() {
                this.loading_submit = true;

                this.$http.post(`/${this.resource}/icbper`, this.form).then(response => {
                    if (response.data.success) {
                        this.$message.success(response.data.message);
                    }
                    else {
                        this.$message.error(response.data.message);
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors;
                    }
                    else {
                        console.log(error);
                    }
                }).then(() => {
                    this.loading_submit = false;
                });
            }
        }
    }
</script>
