<template>
    <div>
        <el-dialog :title="titleDialog" :visible="showDialog" @open="create"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :show-close="false">
            <template v-if="configuration.print_direct==false">
             <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12  ">
                    <el-tabs v-model="activeName"  >
                        <el-tab-pane label="Imprimir A4" name="first">
                            <embed :src="form.print_a4" type="application/pdf" width="100%" height="400px"/>
                        </el-tab-pane>
                        <el-tab-pane label="Imprimir A5" name="second">
                            <embed :src="form.print_a5" type="application/pdf" width="100%" height="400px"/>
                        </el-tab-pane>
                        <el-tab-pane label="Imprimir Ticket" name="third">
                            <embed :src="form.print_ticket" type="application/pdf" width="100%" height="400px"/>
                        </el-tab-pane>
                        <el-tab-pane label="Imprimir Cronograma" name="schedule"  v-if="showSchedule">
                            <embed :src="form.schedule" type="application/pdf" width="100%" height="400px"/>

                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            </template>
            <template v-else>
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-3 col-sm-12 text-center font-weight-bold mt-3">
                    <button type="button" class="btn btn-lg btn-info waves-effect waves-light" @click="clickPrint('a4')">
                        <i class="fa fa-file-alt"></i>
                    </button>
                    <p>Imprimir A4</p>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 text-center font-weight-bold mt-3">
                    <button type="button" class="btn btn-lg btn-info waves-effect waves-light" @click="clickPrint('a5')">
                        <i class="fa fa-receipt"></i>
                    </button>
                    <p>Imprimir A5</p>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 text-center font-weight-bold mt-3">
                    <button type="button" class="btn btn-lg btn-info waves-effect waves-light" @click="clickPrint('ticket')">
                        <i class="fa fa-receipt"></i>
                    </button>
                    <p>Imprimir Ticket</p>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 text-center font-weight-bold mt-3" v-if="form.view_schedule">
                    <button type="button" class="btn btn-lg btn-info waves-effect waves-light" @click="clickPrint('cronograma')">
                        <i class="fa fa-receipt"></i>
                    </button>
                    <p>Imprimir Cronograma</p>
                </div>
            </div>
           </template>
            <span slot="footer" class="dialog-footer row">
                <div class="col-md-12">
                    <el-input v-model="form.customer_email">
                        <el-button slot="append" icon="el-icon-message"   @click="clickSendEmail" :loading="loading">Enviar</el-button>
                     <i slot="prefix" class="el-icon-edit-outline"></i></el-input>
                </div>
                <div class="col-md-12 m-t-10">
                <template v-if="showClose==true">
                    <el-button @click="clickClose" v-if="editSale==false">Cerrar</el-button>
                    <el-button @click="clickFinalize" v-if="editSale==true">Ir al listado</el-button>
                </template>
                <template v-else>
                    <el-button @click="clickFinalize">Ir al listado</el-button>

                    <el-button type="primary" @click="clickNewSaleNote">Nuevo Nota de venta</el-button>
                </template>
                </div>
            </span>
        </el-dialog>

    </div>
</template>

<script>


    export default {
        props: ['showDialog','configuration', 'recordId', 'showClose','editSale','showSchedule'],
        data() {
            return {
                titleDialog: null,
                loading: false,
                resource: 'sale-notes',
                resource_documents: 'documents',
                errors: {},
                form: {},
                document:{},
                document_types: [],
                all_series: [],
                series: [],
                loading_submit:false,
                showDialogOptions: false,
                documentNewId: null,
                activeName: 'first',
            }
        },
        created() {

            this.initForm()
        },

        methods: {
            initForm() {
                this.errors = {}
                this.form = {
                    id: null,
                    external_id: null,
                    identifier: null,
                    date_of_issue:null,
                    print_ticket: null,
                    schedule: null,
                    print_a4: null,
                    print_a5: null,
                    series:null,
                    number:null,
                    view_schedule:null

                }
            },
            async clickPrintPos(printerName,formatoPdf) {
                 try {
                    this.message="Espere imprimiendo el Comprobante "+this.form.number
                    this.loading_print=true
                    let config = qz.configs.create(printerName, {scaleContent : false},{ jobName:this.form.number });
                    if (!qz.websocket.isActive()) {
                        await qz.websocket.connect(config);
                    }
                    let data = [
                        {
                            type: 'pdf',
                            format: 'file',
                            data: formatoPdf
                        }
                    ];
                    qz.print(config, data).catch((e) => {
                        this.$message.error(e.message);
                    });

                    this.loading_print=false
                    this.clickClose()
                } catch (e) {
                    this.$message.error(e.message);
                }
            },
            clickPrint(format){
                if(this.configuration.print_direct==1){
                    if(format=="a4"){
                        this.clickPrintPos(this.form.printer,this.form.print_a4)
                    }
                    if(format=="a5"){
                        this.clickPrintPos(this.form.printer,this.form.print_a5)
                    }
                    if(format=="ticket"){
                        this.clickPrintPos(this.form.printer,this.form.print_ticket)
                    }
                    if(format=="ticket_50"){
                        this.clickPrintPos(this.form.printer,this.form.ticket_50)
                    }
                    if(format=="cronograma"){
                        this.clickPrintPos(this.form.printer,this.form.schedule)
                    }
                }
                },
           async create() {
                 qz.security.setCertificatePromise((resolve, reject) => {
                this.$http.get('/api/qz/crt/override', {
                    responseType: 'text'
                }).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error.data);
                });
            });
            qz.security.setSignaturePromise((toSign) => {
                return (resolve, reject) => {
                    this.$http.post('/api/qz/signing', {
                            request: toSign
                        })
                        .then(response => {
                            resolve(response.data);
                        }).catch(error => {
                            reject(error.data);
                        });
                };
            });
                await this.$http.get(`/${this.resource}/record/${this.recordId}`)
                    .then(response => {
                        this.form = response.data.data
                        this.titleDialog = `Nota de venta registrada:  ${this.form.serie}-${this.form.number}`
                    })

                // if(this.form.direct_printing=="1"){
                //     let formatoPdf=""
                //     if(this.form.format_printer=="1"){
                //          formatoPdf=this.form.print_a4
                //     }else if(this.form.format_printer=="2"){
                //            formatoPdf=this.form.print_a5
                //     }else if(this.form.format_printer=="3"){
                //            formatoPdf=this.form.print_ticket
                //     }else if(this.form.format_printer=="4"){
                //            formatoPdf=this.form.print_ticket
                //     }

                //     await this.clickPrintPos(this.form.printer,formatoPdf);
                // }

            },
            clickFinalize() {
                location.href = `/${this.resource}`
            },
            clickNewSaleNote() {
                this.clickClose()
            },
            clickClose() {
                this.$emit('update:showDialog', false)
                this.$emit('update:showSchedule', false)
                this.initForm()
            },
            clickDownload(){
                window.open(`/downloads/saleNote/sale_note/${this.form.external_id}`, '_blank');
            },
            clickToPrint(format){
                window.open(`/${this.resource}/print/${this.form.id}/${format}`, '_blank');
            },
            clickSendEmail() {
                this.loading=true
                this.$http.post(`/${this.resource}/email`, {
                    customer_email: this.form.customer_email,
                    id: this.form.id
                })
                    .then(response => {
                        if (response.data.success) {
                            this.$message.success('El correo fue enviado satisfactoriamente')
                        } else {
                            this.$message.error('Error al enviar el correo')
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.errors = error.response.data.errors
                        } else {
                            this.$message.error(error.response.data.message)
                        }
                    })
                    .then(() => {
                        this.loading=false

                    })
            },

        }
    }
</script>
