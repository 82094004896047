<template>
  <div>
    <div class="container-fluid p-l-0 p-r-0">
      <div class="page-header">
        <div class="row">
          <div class="col-sm-6">
            <h6><span>Lista de Ordenes atentidas</span></h6>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/dashboard">Dashboard</a>
              </li>
              <li class="breadcrumb-item active">
                <span class="text-muted">Lista de Ordenes atentidas</span>
              </li>
            </ol>
          </div>
           <!-- <div class="col-12 col-md-6 d-flex align-items-start justify-content-end">
                 <button type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto" @click.prevent="clickCreate()">
                <i class="icofont-plus-circle"></i>
                <span>Nuevo</span>
                </button>
             </div> -->
        </div>
      </div>
    </div>
    <div class="container-fluid p-l-0 p-r-0">
      <div class="card" v-loading="loading">
        <div class="card-header bg-primary">
          <h6 class="my-0 text-white">Lista de Ordenes atentidas</h6>
        </div>
        <div class="card-body">
          <div class="col-md-12 col-lg-12 col-xl-12 mb-2">
            <div class="row">
              <div class="col-md-4 col col-lg-4">
                <label class="control-label">Buscar por</label>
                <el-select @change="reset" v-model="search.column" :key="1">
                  <el-option
                    v-for="(data, index) in columns"
                    :key="index"
                    :label="data.label"
                    :value="data.value"
                  ></el-option>
                </el-select>
              </div>
              <div
                v-if="search.column !== 'date'"
                class="col-md-4 col col-lg-4 d-flex align-items-end"
              >
                <el-input
                  :placeholder="`${
                    search.column == 'orden'
                      ? 'N° de orden'
                      : 'Nombre / N° documento del cliente'
                  }`"
                  v-model="search.value"
                >
                  <i slot="prefix" class="el-icon-edit-outline"></i
                ></el-input>
              </div>
              <div
                v-if="search.column == 'date'"
                class="col-md-4 col col-lg-4 d-flex align-items-end"
              >
                <el-date-picker
                  class="w-100"
                  format="d/MM/yy"
                  value-format="yyyy-MM-dd"
                  v-model="search.value"
                   type="daterange"
                  @change="search_value()"
                >
                </el-date-picker>
              </div>
              <div class="col-md-4 col col-lg-4 d-flex align-items-end"> 
                <button @click="getData" class="btn btn-primary btn-sm mr-5" :loading="loading"><i class="icofont-search"></i> Buscar</button>
                <button @click="DataFoods" class="btn btn-success btn-sm" :loading="loading"><i class="icofont-restaurant"></i> Platos atentidos</button>
              </div>
            </div>
          </div>
          <div class="table-responsive">
             <div>
                <el-pagination
                        @current-change="getData"
                        layout="total, prev, pager, next"
                        :total="pagination.total"
                        :current-page.sync="pagination.current_page"
                        :page-size="pagination.per_page">
                </el-pagination>
            </div>
            <table class="table"  v-loading="loading">
              <thead>
                <tr>
                  <th class="text-center">#</th>
                  <th class="text-center">N° de Comanda</th>
                  <th class="text-center">Hora</th>
                  <th class="text-center">Cliente</th>
                  <th class="text-center">Detalle</th>
                  <th class="text-center">Documento</th>
                  <th class="text-center">Estado</th>
                  <th class="text-center">Acciones</th>
                  <th class="text-center">Total Pagado</th>

                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in records" :key="index" :class="{'bg bg-dark': (row.status_orden_id === '1'),
                           'bg bg-secondary': (row.status_orden_id === '2'),
                           'bg bg-warning': (row.status_orden_id === '3'),
                           'bg-success': (row.status_orden_id === '4'),
                           'bg bg-danger': (row.status_orden_id === '5')
                           }">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-center">
                    {{ row.id }}
                  </td>
                  <td class="text-center">
                    {{ row.orden_items[0].time }}
                  </td>
                  <td class="text-center">
                    {{ row.customer }}
                  </td>

                  <td class="text-center" >
                    <button
                      class="btn btn-sm btn-primary"
                      @click="viewItems(row)">
                      <i class="fas fa-list"></i>
                    </button>
                  </td>

                  <td class="text-center">
                    {{row.document_type}}<br>
                    <span class="badge rounded-pill  text-dark"> {{row.document_number}}</span><br>

                    <button
                      class="btn btn-sm btn-success"
                      @click="viewFile(row)"
                      v-if="row.status_orden_id==4">
                      <i class="fas fa-file"></i>
                        Descargar
                    </button>
                  </td>

                   <td class="text-center">
                        {{row.status}}
                  </td>

                  <td class="text-left">
                        <el-button type="danger" @click="anular_command(row.id)" size="mini"> Anular</el-button>
                        <el-button type="success" @click="sendwhatsapp(row)"  size="mini">Enviar Whatsapp</el-button>
                  </td>
                  <td class="text-center">
                    {{ total(row) }}
                  </td>
                </tr>
              </tbody>
              <tr>
                <td colspan="8" class="text-end">Total de Nota de Venta </td>
                <td class="text-center">{{totals_notas.toFixed(2)}}</td>
              </tr>
              <tr>
                <td colspan="8" class="text-end">Total de Boletas - Facturas </td>
                <td class="text-center">{{totals_facturas.toFixed(2)}}</td>
              </tr>
 <tr>
                <td colspan="8" class="text-end">Total </td>
                <td class="text-center">{{totals_efectivos.toFixed(2)}}</td>
              </tr>
            </table>
            <div>
                <el-pagination
                        @current-change="getData"
                        layout="total, prev, pager, next"
                        :total="pagination.total"
                        :current-page.sync="pagination.current_page"
                        :page-size="pagination.per_page">
                </el-pagination>
            </div>
          </div>
        </div>
        <view-items
          v-if="currentRow"
          :row="currentRow"
          :showDialog.sync="showDialog"
        >
        </view-items>
        <ListFood :showDialogFoods.sync="showDialogFoods" :listFoods.sync="listFoods"></ListFood>
      </div>
    </div>

    <el-dialog
  :title="titleSend"
  :visible.sync="dialogShowDialog"
  width="45%">
   <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label  class="control-label">
            Numero de Whatsapp
          </label>
          <el-input placeholder="Ingrese un Numero de Whatsapp" v-model="number_whatsapp">
            <el-button slot="append" @click="sendwhatsapp_send()"  :loading="loading_submit">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"/>
            </svg> Enviar Comprobante
            </el-button>
          </el-input>
        </div>
      </div>
   </div>
  <span slot="footer" class="dialog-footer">
    <el-button  @click="dialogShowDialog = false" type="primary">Cerrar</el-button>
   </span>
</el-dialog>

  </div>
</template>
<style scoped>
.file1 {
  visibility: hidden;
  position: absolute;
}
</style>
<script>
import queryString from "query-string";

import ListFood from './listFoods.vue'
import {deletable} from '../../../../../../../resources/js/mixins/deletable'
import ViewItems from "./items.vue";
import moment from "moment";
export default {
  components: { ViewItems,ListFood },
  mixins: [deletable],
  props: ["configuration"],
  data() {
    return {
      allRecords: [],
      listFoods:{},
      dialogShowDialog:false,
      loading_submit:false,
      number_whatsapp:null,
      data_document:[],
      titleSend:null,
      columns: [
        {
          id: 1,
          value: "id",
          label: "Número de orden",
        },
        {
          id: 2,
          value: "date",
          label: "Fecha",
        },
        {
          id: 3,
          value: "client",
          label: "Cliente (DNI / RUC)",
        },
      ],
      search: { column: "date", value: moment().format("YYYY-MM-DD") },
      date_start:  moment().format("YYYY-MM-DD"),
      loading: false,
      showDialog: false,
      resource: "ordens",
      currentRow: null,
      showImage: false,
      currentRecord: null,
      currentImage: null,
      areas: [],
      company:{},
      pagination: {},
      statusTable: [],
      records: [],
      totals_notas:0,
      totals_facturas:0,
      totals_efectivos:0,
      showDialogFoods:false
    };
  },
  created() {

    this.$eventHub.$on("reloadData", () => {
      this.getData();
    });
    this.table();
    console.log("search",this.search.value)
    this.getData();
  },
  methods: {
    viewItems(row) {
      this.currentRow = row;
      this.showDialog = true;
    },
    search_value() {
      console.log(this.search.value)
    },
    anular_command(id){
         this.anular_comanda(`/restaurant/worker/destroyorden/${id}`,id).then(() =>
                    this.$eventHub.$emit('reloadData')
        )
    },
    sendwhatsapp_send(){
      if(this.number_whatsapp==null){
        return  this.$message.error("Debe ingresar el numero de whatsapp");
      }
      console.log("sendwhatsapp",this.data_document);
      this.loading_submit = true
      let form={
        id:this.data_document.document_type_id=="01"  ?  this.data_document.document.id : this.data_document.sale_note.id,
        document_id:this.data_document.document_type_id=="01"  ?  this.data_document.document.id : this.data_document.sale_note.id,
        document_type_id: this.data_document.document_type_id,
        customer_telephone:this.number_whatsapp,
        mensaje:"Su comprobante de pago electrónico "+this.data_document.document_type +" "+ this.data_document.document_number+" de *"+this.company.name+"*, ha sido generado correctamente "
    }
    this.$http.post(`/whatsapp`,form).then(response => {
            if (response.data.success == true) {
                this.$message.success(response.data.message)
                this.number_whatsapp =null;
                this.loading_submit = false;
                this.dialogShowDialog = false
            }
        }).catch(error => {
              this.loading_submit = false;
            if (error.response.status === 422) {
                this.errors = error.response.data;
            } else {
                this.$message.error(error.response.data.message);
            }
        }).then(() => {
          this.$message.error(error.response.data.message);
            this.loading_submit = false;
        });
    },
    sendwhatsapp(data){
      this.data_document = data;
      this.titleSend = "Envio de Comprobante "+ this.data_document.document_type +" "+ this.data_document.document_number
      this.dialogShowDialog=true;
    },
    async DataFoods(){
        const response = await this.$http.get(`${this.resource}/listfoods/${this.search.value}`)
        this.listFoods=response.data
        this.showDialogFoods = true
    },
    viewFile(row) {
      let url = "";
      if (row.document) {
        let external_id = row.document.external_id;
        url = `/downloads/document/pdf/${external_id}`;
      }
      if (row.sale_note) {
        let external_id = row.sale_note.external_id;
        url = `/sale-notes/print/${external_id}/a4`;
      }
      window.open(url, "_blank");
    },
    total(row) {
      if (row.document) {
        return row.document.total;
      }
      if (row.sale_note) {
        return row.sale_note.total;
      }
      return 0.0;
    },
    getQueryParameters() {
      let desde = this.search.value[0];
      let hasta = this.search.value[1];
      if (this.search.column == "date") {
        return "column=date&page="+this.pagination.current_page+"&desde="+desde+"&hasta="+hasta;        
      } else {
        return queryString.stringify({
                page: this.pagination.current_page,
                limit: this.limit,
                ...this.search
        })  
      }
            
    },
    reset() {
      this.search.value = "";
    },
    Totals() {
      let facturas = _.filter(this.records, { 'document_type_id': "01" });

      let notas_venta = _.filter(this.records, {'document_type_id': "80"});
    
      this.totals_facturas = _.sumBy(facturas, (it) => parseFloat(it.total));
      this.totals_notas = _.sumBy(notas_venta, (it) => parseFloat(it.total));
      this.totals_efectivos = _.round(
        this.totals_facturas + this.totals_notas,
        2
      );
    },
    async table(){
        const response = await this.$http.get(`pos/tables`);
        this.company = response.data.company;
     },
    async getData() {
      this.loading=true;
      //let query = queryString.stringify(this.search);
      const response = await this.$http.get(`${this.resource}/records?${this.getQueryParameters()}`);
      this.pagination = response.data.meta
      this.pagination.per_page = parseInt(response.data.meta.per_page)
      this.records = response.data.data;
      this.loading=false;
      this.Totals()
    },

    clickDelete(id) {
      this.destroy(`/${this.resource}/${id}`).then(() =>
        this.$eventHub.$emit("reloadData")
      );
    },
  },
};
</script>
